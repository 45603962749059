import React from 'react';

export default function PolitikaZasebnosti() {
  import('../App.css');

  return (
    <div style={{ margin: "0 auto"}}>

      <div className="containrforPrivacyPolicy" >
      <p style={{textAlign: "center", marginTop: "50px", marginBottom: "50px"}} ><b><span  >POLITIKA ZASEBNOSTI</span></b></p>

<p style={{ marginBottom: "10px"  }}  ><span  >Ta politika zasebnosti, skupaj s Splošnimi pogoji uporabe spletne strani in Politiko uporabe piškotkov, določa načine uporabe osebnih podatkov, ki jih od vas, z vašo uporabov zvezi z uporabo spletne strani </span><span ><a href="https://www.merkur-zav.si/dr-merkur"><span >https://www.merkur-zav.si/dr-merkur</span></a></span><span  >in vseh morebitnih podstrani te spletne strani (»spletna stran«), pridobiupravljavecMerkur zavarovalnica d.d.ter obdelovalec NYD d.o.o., z registriranim naslovom in poslovnim naslovom na Prešernova cesta 19, 1000 Ljubljana(v nadaljevanju "NYD", "mi", "nas").</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Z uporabo storitev Dr. Merkur dovoljujete, da NYD d.o.o. posreduje podatke o uporabi storitveDr. Merkur (t.j. datum registracije, datumi posameznih prijav v sistem, tip storitve, takojšnja obravnava ali rezervacija termina) Merkur zavarovalnici d.d., ki jih lahko do vašegapreklica uporablja za namen analize uporabe storitve, skladno s to politiko zasebnosti. Preklic lahko podate kadarkoli in brez kakršnihkoli stroškov.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Za namene uporabe storitve Dr. Merkur, ki omogoča uporabnikom (»vas«, »vam«) posvet in možnost oddaje neposrednega povpraševanja na zdravstvene ali z zdravjem povezane storitve inoddajo mnenja in ocene o zadovoljstvu z opravljeno zdravstveno ali z zdravjem povezano<span >  </span>storitvijo na spletni strani, v tej politiki zasebnosti nastopa:</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Merkur zavarovalnica d.d., Dunajska cesta 58, </span><span ><a href="mailto:info@merkur-zav.si"><span >info@merkur-zav.si</span></a></span><span  >, 080 28 55,kot upravljalec,</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >NYD d.o.o, Prešernova cesta 19, 1000 Ljubljana, </span><span ><a href="mailto:info@nyd.care"><span >info@nyd.care</span></a></span><span  >, 051 426 911, kot obdelovalec,</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Zdravniki in zdravstveni sodelavci ter druge osebe, v skladu s to politiko zasebnosti kot pod-obdelovalci.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Za uporabo Dr. Merkur ima Merkur zavarovalnica d.d. sklenjeno posebno pogodbo o obdelavi osebnih podatkov, ki natančno ureja kako, in pod kakšnimi pogoji smejo NYD d.o.o.<span >  </span>ter njegovi podobdelovalciobdelovati osebne podatke,glede katerih Merkur zavarovalnica d.d. nastopakot upravljalec <i>(npr. osebna imena, naslovi, elektronski naslovi, IP naslovi, in drugi podatki, ki se nanašajo na uporabnike).</i></span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  cccccccc><span >1.<span >        </span></span></span></b><b><span  >Osebni podatki, zbrani z uporabo kontaktnega obrazca na Spletni strani</span></b></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >a)<span >      </span></span></span></b><b><span  >Zbrani osebni podatki in podlaga za zbiranje</span></b></p>
<p style={{ marginBottom: "10px"  }}  ><span  >NYD zbira podatke o vašem imenu, priimku, davčni številki in elektronskem naslovu, starost, spol, telefonska številka, bolezenski znaki, zdravstveni podatek,zgolj z vašo privolitvijo, ki jo podate ob posredovanju sporočila prek kontaktnega obrazca na Spletni strani (v nadaljevanju: »obrazec za storitev«). </span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Posredovanje osebnih podatkov ni pogoj za uporabo storitev NYD, razen ko je to nujno potrebno za zagotavljanje teh storitev (npr. povratni stik NYD s posameznikom zahteva vnos vsaj enega kontaktnega podatka posameznika).Za uporabo storitve Dr. Merkur nujno obdelujemo vaše osebne podatke, posredovane na podlagi obrazca za storitev Dr. Merkur. Če se z obdelavo osebnih podatkov, ki so nujno potrebni za izvajanje storitev, ne strinjate, izvedba zdravstvene storitve v sklopu uporabe aplikacije Dr. Merkur ni mogoča.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >b)<span >      </span></span></span></b><b><span  >Namen zbiranja podatkov</span></b></p>
<p style={{ marginBottom: "10px"  }} ><span  >Osebne podatke pridobljene z uporabo kontaktnega obrazca na Spletni strani bo NYD obdeloval zgolj z namenom oblikovanja odgovora naposredovana vprašanja, za verifikacijo upravičenosti do uporabe storitve, komentarje, povpraševanja ali sporočilain posredovanja tovrstnega odgovora na vaš elektronski naslov/oziroma preko video/avdio/glasovnega/besedilnega sporočila ter skladno s 3.členom te politike zasebnosti.Vsi osebni podatki, ki nam jih posredujete, bodo obravnavni zaupno, s strani oseb, ki so se pogodbeno zavezale k varovanju osebnih podatkov.</span></p>
<p style={{ marginBottom: "10px"  }} ><span  > </span></p>
<p style={{ marginBottom: "10px"  }} ><span  >Če bo nastala potreba po nadaljnji obdelavi vaših osebnih podatkov za drug namen, kot namen v katerega ste privolili, vas bomo o tem predhodno obvestili ter zaprosili za vašo predhodno pisno privolitev.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >c)<span >      </span></span></span></b><b><span  >Hramba osebnih podatkov</span></b></p>
<p style={{ marginBottom: "10px"  }}  ><span  >NYD vaše osebne podatke hrani v skladu z enim izmed spodaj naštetih obdobij hrambe:</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >v primeru, ko se vaši podatki obdelujejo zgolj na podlagi vaše izrecne privolitve, <b>do preklica privolitve</b> k obdelavi osebnih podatkov z vaše strani,</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >v primeru, ko je bil namen, za katerega so bili osebni podatki pridobljeni, že izpolnjen, pa pred tem ni prišlo do preklica privolitve k obdelavi osebnih podatkov, <b>do izpolnitve namena</b>ali</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >v kolikor je hramba osebnih podatkov potrebna za zavarovanje interesov NYD v zvezi z vsebino komunikacije, začete preko kontaktnega obrazca, <b>do poteka rokov za uveljavljanje vaših pravic in obveznosti ali pravic in obveznosti NYD</b>;</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >v primeru, da ste na obrazcu za storitev priložili tudi vaše izvide, ali kakršnekoli priloge se bodo le-te trajno izbrisale v roku 48 ur po zaključeni oziroma opravljeni storitvi.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }} ><span  >Po preteku obdobja hrambe osebnih podatkov bo NYD podatke trajno izbrisal ali anonimiziral, s tem pa osebnih podatkov ne bo več mogoče povezati z vami.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >2.<span >       </span></span></span></b><b><span  >Osebni podatki zbrani z namestitvijo piškotkov</span></b></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Za namene uporabe storitve Dr. Merkur NYD ne namešča piškotkov. Piškotki so nameščeni, v skladu s politiko piškotkov, objavljeno na </span><span ><a href="https://www.merkur-zav.si/de/politika-piskotkov"><span >https://www.merkur-zav.si/de/politika-piskotkov</span></a></span><span  >.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >3.<span >       </span></span></span></b><b><span  >Podatki o oceni o opravljeni storitvi</span></b></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >V primeru, da se uporabnik eksplicitno strinja (aktivno odda strinjanje) ob začetku procesa storitve ali sorodnega procesa na spletni strani, lahko uporabnika pozovemo k oddaji ocene oz. mnenja o opravljeni storitvi po tem, ko bo storitev zaključena. V ta namen lahko skladno s to politiko zasebnosti hranimo uporabnikov elektronski naslov, termin, čas trajanja posveta,<span >  </span>opravljeno storitevin izbranega zdravstvenega sodelavca – v primeru da je ta podatek na voljo za namen in čas izvedbe vprašanja o zadovoljstvu po opravljeni storitvi.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Za namen oddaje mnenja in ocene po opravljeni storitvi uporabnik spletnega mesta s potrditvijo Splošnih pogojev potrjuje, da je seznanjen, da mu bo spletna stran po opravljeni storitvi ponudila vprašalnik o zadovoljstvu na spletni strani. Uporabnik spletnega mesta se nato prostovoljno odloči, ali bo na vprašanja odgovarjal in podal mnenje ali ne. Za namen analize vprašalnika izvajalec obdeluje osebne podatke uporabnika. V primeru odgovarjanja na vprašanja in oddaje mnenja in ocene po opravljeni storitvi izvajalec obdeluje termin storitve (če je podatek na voljo) in informacije o storitvi, ki jo je uporabnik koristil. V primeru obdelave posebnih vrst osebnih podatkov za to pridobimo predhodno privolitev posameznika v skladu z veljavnimi predpisi s področja varstva osebnih podatkov. </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  > </span></b></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >4.<span >      </span></span></span></b><b><span  >Vaše pravice v zvezi z zbranimi osebnimi podatki</span></b></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Vsakdo ima, z namenom zagotoviti transparentno in zakonito obdelavo ter zaščititi svojo zasebnost, pravico dostopati do osebnih podatkov, pravico zahtevati popravo osebnih podatkov, pravico zahtevati izbris osebnih podatkov, pravico zahtevati omejitev obdelave osebnih podatkov, pravico do preklica privolitve k obdelavi osebnih podatkov, pravico do prenosljivosti osebnih podatkov in pravico pritožiti se informacijskemu pooblaščencu v zvezi z obdelavo njegovih osebnih podatkov, pod pogoji določenimi v veljavnih predpisih.Vse pravice so natančneje opisane spodaj.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Vse zahteve s katerimi želite uveljavljati katerokoli izmed zgoraj naštetih pravic lahko posredujete naprivacy@nyd.care . NYD si pridržuje pravico, da pred nadaljnjim postopanjem glede vaše zahteve preveri vašo identiteto.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Merkur zavarovalnica d.d. je upravljavec podatkov za vso obdelavo osebnih podatkov, povezanih z vami. Vprašanja in zahteve glede obdelave osebnih podatkov s strani podjetja Nyd lahko pošljete podjetju Nyd ali našemu uradniku za varstvo podatkov na naslov privacy@nyd.care ali v skladu z9.točko te politike zasebnosti. Na nas se lahko obrnete tudi preko zgoraj navedenega fizičnega naslova.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >a)<span >      </span></span></span></b><b><span  >Pravica do dostopa do osebnih podatkov</span></b></p>
<p style={{ marginBottom: "10px"  }} ><span  >V vsakem trenutku smete zahtevatipotrditev, ali vaše osebne podatke obdelujemo. V primeru obdelovanja osebnih podatkov smete nadalje zahtevati informacije o namenu obdelave, vrsti osebnih podatkov, ki se obdelujejo, o osebah, ki vaše osebne podatke obdelujejo, o obdobjih hrambe oz. merilih za določitev obdobij hrambe, o obstoju pravice do popravka, pravice do izbrisa in pravice do omejitve obdelave osebnih podatkov, o pravici do ugovora zoper obdelavo osebnih podatkov, o pravici do vložitvepritožbe pri nadzornem organu, o viru podatkov, če osebni podatki niso bili pridobljeni neposredno od vas in o morebitnem obstoju avtomatiziranega sprejemanja odločitev.</span></p>
<p style={{ marginBottom: "10px"  }} ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >b)<span >      </span></span></span></b><b><span  >Pravica do poprave zbranih osebnih podatkov</span></b></p>
<p style={{ marginBottom: "10px"  }}  ><span  >V kolikor vaši osebni podatki, obdelovani s strani NYD, niso popolni ali pa so napačni, smete zahtevati, da se takšni osebni podatki nemudoma popravijo ali dopolnijo.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >                                                        </span></span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >c)<span >      </span></span></span></b><b><span  >Pravica do izbrisa osebnih podatkov</span></b></p>
<p style={{ marginBottom: "10px"  }} ><span  >Izbris celote osebnih podatkov, ki so za obdelavo na voljo NYD,se sme zahtevati v naslednjih primerih:</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >če je namen obdelave, za katerega so bili vaši osebni podatki zbrani, prenehal,</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >če ste preklicali privolitev k obdelavi osebnih podatkov in za obdelavo ne obstaja nobena druga pravna podlaga,</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >če ste obdelavi osebnih podatkov ugovarjali in so izpolnjeni vsi z zakonom določeni pogoji,</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >če je NYD osebne podatke obdeloval na nezakonit način ali</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >če je izbris osebnih podatkov zahtevan v skladu z obveznostjo NYD.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >d)<span >      </span></span></span></b><b><span  >Pravica do omejitve obdelave osebnih podatkov</span></b></p>
<p style={{ marginBottom: "10px"  }} ><span  >Obdelavo vaših osebnih podatkov smete kadarkoli omejiti, v kolikor:</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >podatki, ki se obdelujejo niso pravilni, nepravilnost podatkov pa se nanaša na obdobje, ki ga NYD kot obdelovalec podatkov lahko preveri,</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >se podatki obdelujejo nezakonito, ali</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >jih potrebujete za uveljavljanje, izvajanje ali obrambo pravnih zahtevkov, NYD kot obdelovalec osebnih podatkov pa teh podatkov ne potrebuje več, saj je namen obdelave prenehal.</span></p>
<p style={{ marginBottom: "10px"  }}><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >e)<span >      </span></span></span></b><b><span  >Pravica do preklica privolitve</span></b></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Privolitev k obdelavi osebnih podatkov, ki ste jo dali skladno z drugo in/ali tretjo točko te politike zasebnosti, lahko kadar koli prekličete, ne da bi to vplivalo na zakonitost obdelave podatkov, ki se je na podlagi privolitve izvajala do njenega preklica.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >f)<span >       </span></span></span></b><b><span  >Pravica do prenosljivosti osebnih podatkov</span></b></p>
<p style={{ marginBottom: "10px"  }} ><span  >Svoje osebne podatke, ki se obdelujejo s strani NYD, smete kadarkoli zahtevati v strukturirani, splošno uporabljani in strojno berljivi obliki.Tako pridobljene osebne podatke smete kadarkoli posredovati drugemu upravljavcu oziroma zahtevati, da se podatki posredujejo neposredno drugemu obdelovalcu.</span></p>
<p style={{ marginBottom: "10px"  }} ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >g)<span >      </span></span></span></b><b><span  >Pravica do pritožbe Informacijskemu pooblaščencu</span></b></p>
<p style={{ marginBottom: "10px"  }}  ><span  >V kolikor menite, da NYD z vašimi zbranimi osebnimi podatkine ravna na način predpisan z relevantnimi zakonskimi določbami, lahko podate pritožbo Informacijskemu pooblaščencu (Dunajska cesta 22, 1000 Ljubljana, elektronski naslov: gp.ip@ip-rs.si telefon: 012309730, spletna stran: </span><span ><a href="http://www.ip-rs.si/"><span >www.ip-rs.si</span></a></span><span  >).</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >5.<span >       </span></span></span></b><b><span  >Kdo obdeluje osebne podatke?</span></b></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Uporabljamo tehnične in organizacijske varnostne ukrepe za zaščito osebnih podatkov pred nezakonitim ali nepooblaščenim dostopom ali uporabo, pa tudi pred nenamerno izgubo ali okrnitvijo njihove celovitosti. Oblikovali smo jih ob upoštevanju svoje IT infrastrukture, možnega vpliva na zasebnost posameznika v skladu s trenutnimi industrijskimi standardi in prakso. Naši pogodbeni podobdelovalci bodo obdelovali vaše osebne podatke samo, če bodo upoštevali te tehnične in organizacijske varnostne ukrepe.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Ohranjanje varnosti podatkov pomeni varovanje zaupnosti, celovitosti in razpoložljivosti osebnih podatkov:</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >zaupnost in celovitost: osebne podatke posameznikov bomo zaščitili pred nedovoljeno ali nezakonito obdelavo ter pred nenamerno izgubo, uničenjem ali poškodbo;</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  ><span >-<span >          </span></span></span><span  >razpoložljivost: zagotovili bomo, da lahko pooblaščeni obdelovalci dostopajo do osebnih podatkov, zgolj in samo kadar je to potrebno.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Naši varnostni postopki vključujejo: varnost dostopa, varnostne kopije, spremljanje, pregled in vzdrževanje, upravljanje varnostnih incidentov itd.Glede na namene, za katere obdelujemo osebne podatke posameznikov, le-te lahko razkrijemo naslednjim kategorijam obdelovalcev:</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >a) Znotraj podjetja: zaposleni v podjetju.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >b) Našim poslovnim partnerjem, od katerih zahtevamo, da vedno ravnajo v skladu z veljavnimi zakoni, politiko varstva osebnih podatkov ter posvečajo veliko pozornosti zaupnosti vaših osebnih podatkov:</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >- fizične in pravne osebe, ki so naši pogodbeni partnerji in za katere smo pridobili privolitev upravljalavcater za obdelovalca izvajajo svetovanja ali posamezne storitve z namenom izvrševanja pogodbenega razmerja;</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >c) Drugim tretjim osebam:Kadar to zahteva zakon ali je zakonito potrebno za zaščito:</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >- podjetja (skladnost z zakoni, zahteve organov, sodne odredbe, pravni postopki, obveznosti, povezane s poročanjem in vlaganjem informacij organom itd.)</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >- <span > </span>preverjanje ali uveljavljanje skladnosti s politiko in sporazumi podjetja;</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Naši poslovni partnerji, navedeni zgoraj pod točko b), smejo osebne podatke posameznikov obdelovati zgolj v okviru naših navodil in osebnih podatkov ne smejo uporabiti za zasledovanje kakršnihkoli lastnih interesov. Vsak posameznik pa mora upoštevati, da podobdelovalci, navedeni v zgornjih točkah b) in c), zlasti ponudniki storitev, ki vam lahko ponujajo storitve v okviru in/ali aplikacij ali preko lastnih kanalov, lahko ločeno zbirajo vaše osebne podatke. V takem primeru so izključno odgovorni za njihov nadzor, sodelovanje posameznikov z njimi pa mora potekati skladno z njihovimi pogoji.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >d) Informacije, ki jih delimo z drugimi</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Podatki, ki vas identificirajo: Osebne podatke, iz katerih vas lahko prepoznamo, bomo delili le v določenih omejenih okoliščinah, kot je opisano spodaj.</span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  >5.1.</span></b><span  >Zdravniki, zdravstveni sodelavci–zdravstvene podatke o vas, lahko delimo z zdravniki (vsi so registrirani pri Zdravniški zbornici), ki bodo ocenili vaše zdravstveno stanje. Vaše zdravstvene podatke delimo z zdravniki in zdravstvenimi sodelavci, da jim omogočimo, da bolje ocenijo zdravstveno stanje, vam svetujejo in zagotovijo storitve, ki jih zahtevate (v skladu z našimi pogoji). </span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Upoštevajte, da države zunaj EGP morda ne bodo nudile enake ravni varstva podatkov, čeprav bo naše zbiranje, shranjevanje in uporaba vaših osebnih podatkov še naprej urejala ta politika zasebnosti. Zagotavljamo, da so vsi podatki, posredovani zdravnikom, zaščiteni z ustreznimi in ustreznimi zaščitnimi ukrepi, vsi zdravniki pa so vezani na pogodbene obveznosti, ki vključujejo vzorčne klavzule Evropske komisije, da vsem zdravnikom zagotovijo, da osebne podatke, ki jih prejmejo, hranijo varne, zaupne in jih uporabljajo leza namene, za katere so jim na voljo.</span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  >5.2.</span></b><span  >Z Merkur zavarovalnico d.d., kot upravljavcu osebnih podatkov, se delijo vsi osebni podatki, ki so zbrani na podlagi vašega soglasja, razen podatkov o zdravstvenih kartotekah. Merkur zavarovalnica d.d. lahko delipodatke z NYD pri uporabi spletne strani, v skladu z njihovo politiko zasebnosti in splošnimi pogoji. Če se bo položaj spremenil, vas bomo vprašali, preden bomo podatke delili na ta način, razen če ste svoje soglasje že navedli neposredno pri Merkur zavarovalnici d.d.</span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  >5.3.</span></b><span  >Ponudniki sporočilin komunikacija z uporabnikom</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Nekateri podatki iz komunikacij s telefonom, videom ali besedilnimi sporočili se lahko začasno prenesejo in shranijo na strežnike zunaj EGP.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Twilio, naš ponudnik videa, glasovnih in besedilnih ter SMS sporočil, ima sedež v ZDA. Twilio je organizacija, skladna z GDPR, registrirana z Privacy Shield in ISO 27001. Twilio je po pogodbi vezan na podjetje NYD d.o.o., ki vsebuje standardne pogodbene klavzule EU za GDPR. Več informacij najdete na spletnem mestu <a c>Twilio.</a></span><span ><span  ><a id="_anchor_1"  href="file:///C:/Users/Q/Downloads/NyD_Politika_zasebnosti_20200420_DR_Merkur_KS_GR_MR_-_1205202_kon%C4%8Dni_tra..._mr.docx#_msocom_1" name="_msoanchor_1">[MR1]</a><span s> </span></span></span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Podizvajalec in posledično izvajalec ne jamčita za nedostavljena sporočila zaradi izklopljenega ali nedosegljivega mobilnega telefona za več kot 72 ur oz. za nastavljeni čas veljavnosti poslanega sporočila.Za pošiljanje avtomatiziranih sporočil SMS uporabljamo storitev ponudnika <a >Twilio.</a></span><span ><span  ><a id="_anchor_2"  href="file:///C:/Users/Q/Downloads/NyD_Politika_zasebnosti_20200420_DR_Merkur_KS_GR_MR_-_1205202_kon%C4%8Dni_tra..._mr.docx#_msocom_2" name="_msoanchor_2">[MR2]</a><span s> </span></span></span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >S ponudnikom imamo sklenjeno pogodbo o obdelavi osebnih podatkov. NYDdaje ponudniku pošiljanja sporočil SMS pravico do uporabe, shranjevanja in reproduciranja sporočil SMS le za namen zagotavljanja poti za pošiljanje naslovnikom.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Amazon AWS, naš ponudnik elektronskih in besedilnih sporočil, ima sedež v ZDA. Amazon AWS je organizacija, skladna z GDPR, registrirana z Privacy Shield in ISO ISO/IEC 27001:2013, 27017:2015, 27018:2019, and ISO/IEC 9001:2015 and CSA STAR CCM v3.0.1. Amazon AWS je po pogodbi vezan na podjetje NYD d.o.o., ki vsebuje standardne pogodbene klavzule EU za GDPR. Več informacij najdete na spletnem mestu Amazon AWS<a>.</a></span><span ><span  ><a id="_anchor_3"  href="file:///C:/Users/Q/Downloads/NyD_Politika_zasebnosti_20200420_DR_Merkur_KS_GR_MR_-_1205202_kon%C4%8Dni_tra..._mr.docx#_msocom_3" name="_msoanchor_3">[MR3]</a><span s> </span></span></span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Storitvi elektronskega obveščanja preko sporočil SMS in elektronske pošte se izvajata prek spletne aplikacije ali vmesnika, ki ga ponuja izvajalčev podizvajalec. Pred obveščanjem bo NYDod uporabnika kot prejemnika pridobil jasno, nedvoumno in s Splošno uredbo o varstvu podatkov skladno privolitev za prejem tovrstnih sporočil. Sporočila SMS in elektronska pošta so posredovani v zunanji sistem,v skladu s to politiko zasebnosti.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Podizvajalec in posledično izvajalec ne jamčita za nedostavljena elektronska sporočila zaradi začasnih ali trajnih blokad poštnega strežnika prejemnika, ki so lahko posledica predhodno prejetih elektronskih sporočil drugih ponudnikov, ki uporabljajo isti IP naslov ali sorodnega IP naslovnega prostora, ki bi lahko povzročil blokado poslanega sporočila.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >6.<span >      </span></span></span></b><b><span  >NYD kot obdelovalec podatkov</span></b></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  > </span></b></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Kot del zagotavljanja spletne strani mora NYD začasno obdelati podatke, ki lahko predstavljajo osebne podatke o uporabnikih, vključno s šifriranimi video datotekami, telefonsko številko, elektronskim naslovom, davčno številko in naslovom IP. NYD do teh podatkov po koncu posvetovanj ne dostopa, in ne shranjuje. Kljub temu hranimo dnevnike posvetovanj, vključno s časovnimi žigi. </span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Merkur zavarovalnica d.d. je v vlogi upravljavca podatkov za obdelavo, ki poteka v okviru posvetovanja, uporabi Dr. Merkur storitev in komuniciranja z uporabniki na spletni strani, kjer je Merkur zavarovalnica d.d. odgovornaza to, da NYD lahko zakonito obdeluje take osebne podatke (v vlogi obdelovalca), vključno z, vendar ne omejeno na, obveščanjemuporabnikov o takšni obdelavi, njihovih pravicah in kontaktnih podatkih ter pridobitvijo vseh potrebnih privolitev in / ali pooblastil za zbiranje in uporabo takih osebnih podatkov prek spletne strani. NYD v imenu Merkur zavarovalnice d.d. obdeluje osebne podatke v skladu z medsebojno pogodbo med NYD in Merkur zavarovalnico d.d. o obdelavi podatkov, priloženo določilom krovne pogodbe.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >7.<span >       </span></span></span></b><b><span  >Sprememba politike zasebnosti</span></b></p>
<p style={{ marginBottom: "10px"  }}  ><span  >NYD si pridržuje pravico kadarkoli spremeniti vsebino te politike zasebnosti. Spremenjena politika zasebnosti velja od trenutka objave na Spletni strani in ureja nadaljnjo obdelavo že zbranih osebnih podatkov in obdelavo osebnih podatkov, zbranih po začetku veljavnosti spremenjene politike zasebnosti. Priporočamo, da pred vsakim posredovanjem osebnih podatkov preverite aktualno različico politike zasebnosti, da boste seznanjeni z morebitnimi spremembami in dopolnitvami.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >V kolikor bo prišlo do bistvene spremembe te politike zasebnosti (npr. sprememba namena obdelave ali sprememba kategorij osebnih podatkov, ki se zbirajo), vas bomo o spremembi obvestili, hkrati pa zahtevali ponovno privolitev k obdelavi osebnih podatkov.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Datum zadnje spremembe te politike zasebnosti je objavljen spodaj.</span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  > </span></b></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >8.<span >      </span></span></span></b><b><span  >Pooblaščena oseba za varstvo osebnih podatkov </span></b></p>
<p style={{ marginBottom: "10px"  }}  ><span  >Za nadzor nad skladnostjo s Politiko zasebnosti smo imenovali pooblaščeno osebo za varstvo osebnih podatkov (DPO). Če imate kakršno koli vprašanje v zvezi s to Politiko zasebnosti ali o tem, kako ravnamo z vašimi osebnimi podatki, se obrnite na pooblaščeno osebo za varstvo osebnih podatkov pri NYD,Mašo Rupnik, univ.dipl.prav.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><b><span  ><span >9.<span >      </span></span></span></b><b><span  >Vprašanja in zahteve</span></b></p>
<p style={{ marginBottom: "10px"  }}  ><span  >V primeru kakršnihkoli vprašanj in zahtev nas prosim kontaktirajte na info@nyd.care.</span></p>
<p style={{ marginBottom: "10px"  }}  ><span  > </span></p>
<p style={{ marginBottom: "10px"  }}  ><sup><span  >©</span></sup><span  >NYD, Ljubljana, april 2021.</span></p>

<div >

<hr  />


<div >

</div>
<div >

</div>

</div>

</div>
    </div>
  );
}
