import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
//images

import grozno_positive from "../../assets/rating/grozno.svg";
import slab_positive from "../../assets/rating/slab.svg";
import povprecno_positive from "../../assets/rating/povprecno.svg";
import dobro_positive from "../../assets/rating/dobro.svg";
import odlicno_positive from "../../assets/rating/odlicno.svg";
import grozno_negativ from "../../assets/grozno_negativ.svg";
import slab_negativ from "../../assets/slab_negativ.svg";
import povprecno_negativ from "../../assets/povprecno_negativ.svg";
import dobro_negativ from "../../assets/dobro_negativ.svg";
import odlicno_negativ from "../../assets/odlicno_negativ.svg";

import { Hidden } from "@material-ui/core";



//Dinamic Images
const feedback_success = require(`../../assets/${process.env.REACT_APP_PROJECT}/feedback_success.png`).default;
const merkurLogo  = require(`../../assets/${process.env.REACT_APP_PROJECT}/merkur.svg`).default;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    padding: "1em 5em",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  padded: {
    padding: "3em 15em 2em 15em",
    background: "#A6D586",
  },
}));



export default function HorizontalLinearStepper({...props}) {
  import('./App.css');

  console.log(props)
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  // The first commit of Material-UI

  const [state, setState] = React.useState({
    grozno: true,
    slab: true,
    povprecno: true,
    dobro: true,
    odlicno: true,
    vsebina: false,
    prijaznost: false,
    odnos: false,
    strokovnost: false,
    enostavnost: false,
    hitrost: false,
    drugo: "",
    placevanje: false,
    fiveEuros: false,
    tenEuros: false,
    twentyEuros: false,
  });

  function getSteps() {
    return ["Prijava"];
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleMoney = (data) => {
    switch (data) {
      case "fiveEuros":
        setState({
          ...state,
          fiveEuros: true,
          tenEuros: false,
          twentyEuros: false,
          placevanje: true,
          willingToPay: "do 2.5 € na mesec",
        });
        break;
      case "tenEuros":
        setState({
          ...state,
          fiveEuros: false,
          tenEuros: true,
          twentyEuros: false,
          placevanje: true,
          willingToPay: "do 5 € na mesec",
        });
        break;
      case "twentyEuros":
        setState({
          ...state,
          fiveEuros: false,
          tenEuros: false,
          twentyEuros: true,
          placevanje: true,
          willingToPay: "do 10 € na mesec",
        });
    }
  };


  // useEffect(() => {
  //   setState({...state, placevanje: true})
  // }, [])

// preveri ali je mozno dati da kot true nameste ne v placevanje state


  const handleRating = (data) => {
    switch (data) {
      case "grozno":
        setState({
          grozno: true,
          slab: false,
          povprecno: false,
          dobro: false,
          odlicno: false,
          rating: "grozno",
        });
        break;
      case "slab":
        setState({
          grozno: false,
          slab: true,
          povprecno: false,
          dobro: false,
          odlicno: false,
          rating: "slab",
        });
        break;
      case "povprecno":
        setState({
          grozno: false,
          slab: false,
          povprecno: true,
          dobro: false,
          odlicno: false,
          rating: "povprecno",
        });
        break;
      case "dobro":
        setState({
          grozno: false,
          slab: false,
          povprecno: false,
          dobro: true,
          odlicno: false,
          rating: "dobro",
        });
        break;
      case "odlicno":
        setState({
          grozno: false,
          slab: false,
          povprecno: false,
          dobro: false,
          odlicno: true,
          rating: "odlicno",
        });
        break;
    }
  };

  const {
    vsebina,
    prijaznost,
    odnos,
    strokovnost,
    enostavnost,
    hitrost,
    drugo,
    placevanje,
    fiveEuros,
    tenEuros,
    twentyEuros,
    willingToPay,
    ratingOptions,
    rating,
  } = state;

  const handleSelection = (event) => {
    console.log(event);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div className="feedbackForm">
            <h1>Video posvet je končan</h1>
            <h2>Kako bi ocenili posvet?</h2>
            <h3>Vaše povratne informacije nam pomagajo izboljšati storitev.</h3>

            <div className="feedbackFormRating">
              <div className="item smiley">
                <img
                  src={state.grozno ? grozno_positive : grozno_negativ}
                  onClick={() => handleRating("grozno")}
                ></img>
                {state.grozno ? (
                  <h4>
                    <strong>Grozno</strong>
                  </h4>
                ) : (
                  <h4>Grozno</h4>
                )}
              </div>
              <div className="item">
                <img
                  src={state.slab ? slab_positive : slab_negativ}
                  onClick={() => handleRating("slab")}
                ></img>
                {state.slab ? (
                  <h4>
                    <strong>Slab</strong>
                  </h4>
                ) : (
                  <h4>Slab</h4>
                )}
              </div>
              <div className="item">
                <img
                  src={state.povprecno ? povprecno_positive : povprecno_negativ}
                  onClick={() => handleRating("povprecno")}
                ></img>
                {state.povprecno ? (
                  <h4>
                    <strong>Povprečno</strong>
                  </h4>
                ) : (
                  <h4>Povprečno</h4>
                )}
              </div>
              <div className="item">
                <img
                  src={state.dobro ? dobro_positive : dobro_negativ}
                  onClick={() => handleRating("dobro")}
                ></img>
                {state.dobro ? (
                  <h4>
                    <strong>Dobro</strong>
                  </h4>
                ) : (
                  <h4>Dobro</h4>
                )}
              </div>
              <div className="item">
                <img
                  src={state.odlicno ? odlicno_positive : odlicno_negativ}
                  onClick={() => handleRating("odlicno")}
                ></img>
                {state.odlicno ? (
                  <h4>
                    <strong>Odlično</strong>
                  </h4>
                ) : (
                  <h4>Odlično</h4>
                )}
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="feedbackForm">
            <h1>Video posvet je končan</h1>
            <h2>Katere stvari so vam pri posvetu všeč?</h2>

            <div className="feedbackFormRatingOptions">
              <div
                className={vsebina ? "selected" : "item"}
                onClick={(event) => {
                  setState({ ...state, vsebina: vsebina ? false : true });
                }}
              >
                <h5>Vsebina posveta</h5>
              </div>
              <div
                className={prijaznost ? "selected" : "item"}
                onClick={(event) => {
                  setState({ ...state, prijaznost: prijaznost ? false : true });
                }}
              >
                <h5>Prijaznost</h5>
              </div>
              <div
                className={odnos ? "selected" : "item"}
                onClick={(event) => {
                  setState({ ...state, odnos: odnos ? false : true });
                }}
              >
                <h5>Odnos</h5>
              </div>
              <div
                className={strokovnost ? "selected" : "item"}
                onClick={(event) => {
                  setState({
                    ...state,
                    strokovnost: strokovnost ? false : true,
                  });
                }}
              >
                <h5>Strokovnost</h5>
              </div>
              <div
                className={enostavnost ? "selected" : "item"}
                onClick={(event) => {
                  setState({
                    ...state,
                    enostavnost: enostavnost ? false : true,
                  });
                }}
              >
                <h5>Enostavnost</h5>
              </div>
              <div
                className={hitrost ? "selected" : "item"}
                onClick={(event) => {
                  setState({ ...state, hitrost: hitrost ? false : true });
                }}
              >
                <h5>Hitrost</h5>
              </div>
            </div>
            <div
              style={{
                marginBottom: "8%",
                marginTop: "4%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TextField
                id="outlined-size-normal"
                style={{ margin: 8 }}
                placeholder="+ Dodaj komentar"
                margin="normal"
                onChange={(event) => {
                  setState({ ...state, drugo: event.target.value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="feedbackForm">
            <h1>Video posvet je končan</h1>
            <h2>Ali ste pripravljeni za takšno storitev doplačati?</h2>
            <div className="feedbackFormRatingOptions halfGrid">
              <Button
                className={placevanje ? "button selected" : "button item"}
                onClick={(event) => {
                  setState({
                    ...state,
                    placevanje: true,
                    ratingOptions: {
                      vsebina: vsebina,
                      prijaznost: prijaznost,
                      strokovnost: strokovnost,
                      odnos: odnos,
                      hitrost: hitrost,
                      enostavnost: enostavnost,
                    },
                  });
                }}
              >
                <h5>Da</h5>
              </Button>
              <Button
                className={!placevanje ? "button selected" : "button item"}
                onClick={(event) => {
                  setState({
                    ...state,
                    placevanje: false,
                    ratingOptions: {
                      vsebina: vsebina,
                      prijaznost: prijaznost,
                      strokovnost: strokovnost,
                      odnos: odnos,
                      hitrost: hitrost,
                      enostavnost: enostavnost,
                    },
                  });
                  setState({
                    fiveEuros: false,
                    tenEuros: false,
                    twentyEuros: false,
                  });
                }}
              >
                <h5>Ne</h5>
              </Button>
            </div>

            {placevanje ? (
              <>
                <h2>Koliko bi bili za takšno storitev pripravljeni plačati?</h2>

                <div
                  className="feedbackFormRatingOptions fullRow"
                  style={{ marginBottom: "10%" }}
                >
                  <div
                    className={fiveEuros ? "selected" : "item"}
                    onClick={() => handleMoney("fiveEuros")}
                  >
                    <h5>do 2,5 € na mesec</h5>
                  </div>
                  <div
                    className={tenEuros ? "selected" : "item"}
                    onClick={() => handleMoney("tenEuros")}
                  >
                    <h5>do 5 € na mesec</h5>
                  </div>
                  <div
                    className={twentyEuros ? "selected" : "item"}
                    onClick={() => handleMoney("twentyEuros")}
                  >
                    <h5>do 10 € na mesec</h5>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        );
      default:
        return "Unknown step";
    }
  }

  const isStepOptional = (step) => {};

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const sendSurvey = (state, props) => {
    console.log('Update survey with survey id', props);
    axios.get(`${process.env.REACT_APP_API_URL}sessions/${props.location.state.surveyId}`,  {
      headers: {
        'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
      }
    }).then((response) => {
      console.log(response);
      axios.patch(`${process.env.REACT_APP_API_URL}sessions/${props.location.state.surveyId}`, {
        id: props.location.state.surveyId,
        rating: rating,
        ratingOptions: ratingOptions,
        comment: drugo,
        willingToPay: willingToPay,
        pacientId: response.data.pacientId,
        doctorId: response.data.doctorId,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
        birthday: response.data.birthday,
        gender: response.data.gender,
      },{
        headers: {
          'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
        }
      })
    })
  };

  return (
    <div className={classes.root} id="feedbackRoot">
      <div id="logo" className="logo-relative-mobile">
        <img src={merkurLogo}></img>
      </div>
      {console.log("The end State", state, process.env.API_URL)}
      {/* <Stepper
        classes={{ root: classes.padded }}
        alternativeLabel
        activeStep={activeStep}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper> */}
      <div className="feedbackView">
        {activeStep === steps.length ? (
          <div className="feedbackFormCentered">
            <h1>Video posvet je končan</h1>
            <h2>Hvala za vaše mnenje</h2>
            <img
              className="imageResponsive"
              style={{ height: "55%", width: "auto" }}
              src={feedback_success}
            ></img>
          </div>
        ) : (
          <div className="stepBoxFeedback">
            <Typography className={classes.instructions}>
              {getStepContent(activeStep)}
            </Typography>
            <div className="stepBoxControllFeedback">
              {activeStep === 0 ||
              (activeStep === steps.length - 1) |
                (activeStep === steps.length - 2) ? (
                <></>
              ) : (
                <Button
                  disabled={
                    activeStep === 0 ||
                    activeStep === steps.length - 1 ||
                    activeStep === steps.length - 2
                  }
                  onClick={handleBack}
                  className={classes.button}
                >
                  Nazaj
                </Button>
              )}
              {isStepOptional(activeStep) && (
                <Button onClick={handleSkip} className={classes.button}>
                  Skip
                </Button>
              )}

            {activeStep === steps.length - 1 ?

            <Button
            onClick={() => {
              handleNext()
              sendSurvey(state, props)
            }}
            className={classes.button}
            id="blueButton"
          >
            Oddaj
          </Button>
          :

          <Button
          onClick={() => {
            handleNext()
          }}
          className={classes.button}
          id="blueButton"
        >
          Naprej
        </Button>}

            </div>
          </div>
        )}
      </div>
    </div>
  );
}
