import axios from "axios";
import React from "react";
import moment from 'moment';
import Countdown from "react-countdown";
import {
  browserName,
  isChrome,
  isEdge,
  isFirefox,
  isIOS,
  isOpera,
  isSafari,
} from "react-device-detect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect, createLocalVideoTrack } from "twilio-video";
import "zone.js/dist/webapis-rtc-peer-connection";
import "zone.js/dist/zone";
import disableAudio from "../assets/disableAudio.svg";
import disabledVideo from "../assets/disabled.svg";
//Images
import camera from "../assets/icon1.svg";
import disconnect from "../assets/icon2.svg";
import mute from "../assets/icon3.svg";
import muted_sound from "../assets/muted_sound.svg";
import muted_video from "../assets/muted_video.svg";

const queryParams = new URLSearchParams(window.location.search);

const roomUID = queryParams.get("room");
const token = queryParams.get("token");
const user = queryParams.get("user");
const timeToStart = queryParams.get("t");
const dt = new Date(timeToStart);


dt.setHours(dt.getHours() + 2);

if(!isDST(dt)){
 // The date is not during Daylight saving time / SummerTime.
  dt.setHours(dt.getHours() + 1);
}

const timeToStartCet = dt.toISOString();
const sessionId = queryParams.get("sessionId");
const doctorFName = queryParams.get("doctorn");
const doctorLName = queryParams.get("doctorln");
const pacientFName = queryParams.get("pacientn");
const pacientLName = queryParams.get("pacientln");
const ph = queryParams.get("ph");

//Dinamic Images
const logoNew =
  require(`../assets/${process.env.REACT_APP_PROJECT}/logo.svg`).default;
const doctorIntro =
  require(`../assets/${process.env.REACT_APP_PROJECT}/doctorIntro.svg`).default;
const logoNewWhite =
  require(`../assets/${process.env.REACT_APP_PROJECT}/merkur.svg`).default;

function NetStatusNotification(isOnline, message) {
  if (isOnline) {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  } else {
    toast.error(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }
}

function isDST(date) {
  var year = date.getFullYear();
   var dstStart = new Date(year, 2, 31); // Last Sunday in March
   var dstEnd = new Date(year, 9, 31);   // Last Sunday in October

   // Adjust dates if they fall outside of March and October
   if (dstStart.getDay() !== 0) {
     dstStart.setDate(dstStart.getDate() - dstStart.getDay()); // Go back to the last Sunday
   }
   if (dstEnd.getDay() !== 0) {
     dstEnd.setDate(dstEnd.getDate() - dstEnd.getDay()); // Go back to the last Sunday
   }

   return date >= dstStart && date < dstEnd;
}

class Video extends React.Component {
  constructor(props) {
    super(props);

    import("../App.css");

    this.state = {
      voiceStatus: true,
      videoStatus: true,
      activeRoom: [],
      localMediaAvailable: true,
      callStarted: false,
      callEnded: false,
      soundDisabled: false,
      videoDisabled: false,
      time: {},
      seconds: 900,
      callCanBeStarted: false,
      pacientData: {},
    };

    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }

  checkOnlineStatus() {
    console.log("Loaded status");
    // 1st, we set the correct status when the page loads
    navigator.onLine
      ? toast.success("Konekcija je uredu!", {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      : toast.error("Konekcija ni uredu!", {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
  }

  startChecker() {
    setInterval(this.checkIfcountdownIsRunning(), 5000);
  }

  startOnlineChecker() {
    setInterval(this.checkOnlineStatus(), 2000);
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    this.startChecker();
    // this.startOnlineChecker();

    window.addEventListener("load", () => {
      console.log("Loaded status");
      // 1st, we set the correct status when the page loads
      navigator.onLine
        ? NetStatusNotification(true, "Vaša povezava je dobra!")
        : NetStatusNotification(false, "Vaša povezava je slaba!");

      // now we listen for network status changes
      window.addEventListener("online", () => {
        console.log("Loaded status online");
        NetStatusNotification(true, "Uspešno ste se ponovno povezali.");
      });

      window.addEventListener("offline", () => {
        console.log("Loaded status offline");
        NetStatusNotification(false, "Vaša povezava je slaba!");
      });
    });
  }

  startCall = () => {
    console.log(this.state);

    let room = connect(token, {
      name: roomUID,
      preferredVideoCodecs: ["H264", "VP8"],
    }).then(
      (room) => {
        console.log(`Successfully joined a Room: ${room}`);

        this.setState({ activeRoom: room });

        if (isIOS) {
        } else {
          createLocalVideoTrack().then((track) => {
            const localMediaContainer =
              document.getElementById("remote-media-div");
            localMediaContainer.appendChild(track.attach());
          });
        }

        // Log your Client's LocalParticipant in the Room
        const localParticipant = room.localParticipant;
        console.log(
          `Connected to the Room as LocalParticipant "${localParticipant.identity}"`
        );

        // Log any Participants already connected to the Room
        room.participants.forEach((participant) => {
          console.log(
            `Participant "${participant.identity}" is connected to the Room  "${participant}"`
          );
        });

        // Attach the Participant's Media to a <div> element.
        room.on("participantConnected", (participant) => {
          console.log(`Participant "${participant.identity}" connected`);

          //video attachment hadnler
          room.participants.forEach((participant) => {
            participant.tracks.forEach((publication) => {
              if (publication.track) {
                document
                  .getElementById("local-media")
                  .appendChild(publication.track.attach());
                document
                  .getElementById("waitingForUser")
                  .classList.add("hidden");
              }

              console.log(localParticipant.videoTracks);
              localParticipant.videoTracks.forEach((publication) => {
                if (isIOS) {
                  document
                    .getElementById("remote-media-div")
                    .appendChild(publication.track.attach());
                } else {
                }
                document
                  .getElementById("waitingForUser")
                  .classList.add("hidden");
              });
            });

            //sound hadnler
            participant.on("trackSubscribed", (track) => {
              document
                .getElementById("local-media")
                .appendChild(track.attach());
              document.getElementById("waitingForUser").classList.add("hidden");

              track.on("disabled", () => {
                if (track.kind === "video") {
                  document
                    .getElementById("disabledVideo")
                    .classList.add("enabledVideoText");
                  console.log("Disabled remote participants", track.kind);
                } else if (track.kind === "audio") {
                  console.log("Disabled remote participants", track.kind);
                  document
                    .getElementById("audioDisabled")
                    .classList.remove("hidden");
                }
              });

              track.on("enabled", () => {
                if (track.kind === "video") {
                  document
                    .getElementById("disabledVideo")
                    .classList.remove("enabledVideoText");
                  console.log("Enabled remote participants", track.kind);
                } else if (track.kind === "audio") {
                  console.log("Enabled remote participants", track.kind);
                  document
                    .getElementById("audioDisabled")
                    .classList.add("hidden");
                }
              });
            });
          });
        });

        //video attachment hadnler
        room.participants.forEach((participant) => {
          participant.tracks.forEach((publication) => {
            if (publication.track) {
              document
                .getElementById("local-media")
                .appendChild(publication.track.attach());
              document.getElementById("waitingForUser").classList.add("hidden");
            }
          });

          //sound hadnler
          participant.on("trackSubscribed", (track) => {
            document.getElementById("local-media").appendChild(track.attach());
            document.getElementById("waitingForUser").classList.add("hidden");

            track.on("disabled", () => {
              if (track.kind === "video") {
                document
                  .getElementById("disabledVideo")
                  .classList.add("enabledVideoText");
                console.log("Disabled remote participants", track.kind);
              } else if (track.kind === "audio") {
                console.log("Disabled remote participants", track.kind);
                document
                  .getElementById("audioDisabled")
                  .classList.remove("hidden");
              }
            });

            track.on("enabled", () => {
              if (track.kind === "video") {
                document
                  .getElementById("disabledVideo")
                  .classList.remove("enabledVideoText");
                console.log("Enabled remote participants", track.kind);
              } else if (track.kind === "audio") {
                console.log("Enabled remote participants", track.kind);
                document
                  .getElementById("audioDisabled")
                  .classList.add("hidden");
              }
            });
          });
        });

        console.log("State in room", this.state);

        return room;
      },
      (error) => {
        console.error(`Unable to connect to Room: ${error.message}`);
      }
    );
  };

  checkIfPacientConnected = () => {
    if (user == "doctor") {
      axios
        .get(`${process.env.REACT_APP_API_URL}sessions/${sessionId}`,
        {
          headers: {
            'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
          }
        }
        )
        .then((response) => {
          console.log(response);
          if (response.data.pacientConnected) {
            if (response.data.pacientConnected == true) {
              console.log(response.data.pacientConnected);
              this.setState({ pacientConnected: true });
            }
          } else {
            console.log(
              "State about pacient does not exist",
              response.data.pacientConnected
            );
            this.setState({ pacientConnected: false });
          }
        });
    } else {
      axios
        .patch(`${process.env.REACT_APP_API_URL}sessions/${sessionId}`, {
          id: sessionId,
          pacientConnected: true,
        },{
          headers: {
            'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
          }
        })
        .then((response) => {
          this.setState({ pacientConnected: true, pacientData: response.data });
        });
    }
  };

  disableLocalMedia = () => {
    console.log(this.props);
    console.log(this.state.activeRoom);
    user == "pacient"
      ? this.props.history.replace("feedback", {
          surveyId: sessionId,
        })
      : this.props.history.replace("doctoroppinion", {
          surveyId: sessionId,
        });
  };

  stopAudio = () => {
    this.state.activeRoom.localParticipant.audioTracks.forEach((track) => {
      track.track.disable();
      this.setState({ soundDisabled: true });
    });
  };

  stopVideo = () => {
    this.state.activeRoom.localParticipant.videoTracks.forEach((track) => {
      track.track.disable();
      this.setState({ videoDisabled: true });
      document
        .getElementById("disabledVideoLocal")
        .classList.add("enabledVideoTextLocal");
    });
  };

  startAudio = () => {
    this.state.activeRoom.localParticipant.audioTracks.forEach((track) => {
      track.track.enable();
      this.setState({ soundDisabled: false });
    });
  };

  startVideo = () => {
    this.state.activeRoom.localParticipant.videoTracks.forEach((track) => {
      track.track.enable();
      this.setState({ videoDisabled: false });
      document
        .getElementById("disabledVideoLocal")
        .classList.remove("enabledVideoTextLocal");
    });
  };

  redirectToPhoneCall = async () => {
    this.props.history.replace("voice", {
      activeRoom: this.state.activeRoom,
      surveyId: sessionId,
      pacientPhone: ph.slice(1),
    });
  };

  renderVideo = () => {
    return (
      <>
        <ToastContainer autoClose={3000} hideProgressBar />
        <div className="video-grid">
          {this.state.callEnded ? (
            <>
              <div className="cancelingCall">
                <p>Končajte ta klic?</p>
                <div className="cancelingCallAction">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ callEnded: false })}
                  >
                    Prekliči
                  </span>
                  <span
                    onClick={() => this.disableLocalMedia()}
                    className="potrdi"
                  >
                    Potrdi
                  </span>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div id="logo">
            {this.state.callStarted ? (
              <img src={logoNew}></img>
            ) : (
              <img src={logoNewWhite}></img>
            )}
          </div>
          <div id="local-media">
            <p id="disabledVideo" class="disabledVideoText">
              <img
                src={disabledVideo}
                style={{ color: "#C4385B", fill: "#C4385B" }}
              ></img>
            </p>

            <p id="audioDisabled" className="hidden">
              <img src={disableAudio} />
            </p>

            <p id="waitingForUser">
              - Počakajte, da se {user == "doctor" ? "pacient" : "zdravnik"}{" "}
              pridruži klicu -
            </p>
          </div>
          <div id="remote-media-div">
            <p id="disabledVideoLocal" class="disabledVideoTextLocal">
              <img
                src={disabledVideo}
                style={{ color: "#C4385B", fill: "#C4385B" }}
              ></img>
            </p>
          </div>
        </div>
        {user == "doctor" ? (
          <div className="phone-call">
            <h5>
              Če video posvet ne dela,<br></br>lahko pokličete pacienta prek
              spleta
            </h5>
            <button
              className="btn-welcome"
              onClick={() => {
                this.props.history.replace("voice", {
                  surveyId: sessionId,
                  pacientPhone: ph.slice(1),
                });
              }}
            >
              Pokliči
            </button>
          </div>
        ) : (
          <></>
        )}
        <div className="control-bar">
          <div className="controls">
            <div className="control">
              <img
                onClick={
                  this.state.soundDisabled
                    ? () => this.startAudio()
                    : () => this.stopAudio()
                }
                src={this.state.soundDisabled ? muted_sound : mute}
              ></img>
              {this.state.voiceStatus}
              {/* <p>Izklop</p> */}
            </div>
            <div className="control">
              <img
                onClick={
                  this.state.videoDisabled
                    ? () => this.startVideo()
                    : () => this.stopVideo()
                }
                src={this.state.videoDisabled ? muted_video : camera}
              ></img>
              {this.state.videoStatus}
              {/* <p>Izklopite kamero</p> */}
            </div>
            <div className="control">
              <img
                src={disconnect}
                onClick={() => {
                  console.log("Should disconnect");
                  this.setState({
                    localMediaAvailable: false,
                    callEnded: true,
                  });
                }}
              ></img>
              {/* <p>Konec</p> */}
            </div>

            <div className="control new-timer">
              <p class="timer">
                {user == "doctor" ? (
                  `${this.state.time.m} : ${this.state.time.s}`
                ) : (
                  <></>
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  checkIfcountdownIsRunning = () => {
    console.log(
      "Current Time",
      new Date().toISOString(),
      "Countdown Time",
      timeToStart
    );
    if (new Date().toISOString() < timeToStart) {
      this.setState({ timerHasFinished: false });
    } else {
      this.setState({ timerHasFinished: true });
      this.checkIfPacientConnected();
    }
  };

  checkBrowserType = () => {
    if (isChrome || isFirefox || isOpera || isSafari || isEdge) {
      return (
        <>
          {user == "pacient" ? (
            <h1>
              {doctorFName} {doctorLName} vas bo zdaj videl.
            </h1>
          ) : (
            <h1>
              Pričeli boste posvet s pacientom {pacientFName} {pacientLName}
            </h1>
          )}
          <h3>Pritisnite gumb "Začni klic", da začnete video posvet.</h3>
          {this.state.timerHasFinished ? (
            user == "doctor" ? (
              this.state.pacientConnected ? (
                ""
              ) : (
                <h3>Počakajte, da se pacient pridruži klicu!</h3>
              )
            ) : (
              ""
            )
          ) : (
            <h3>
              Vaš posvet se bo začel čez:{" "}
              <span>
                {" "}
                <Countdown
                  date={timeToStartCet.substr(0, 19)}
                  onComplete={() =>
                    setInterval(this.checkIfPacientConnected, 5000)
                  }
                />
              </span>
            </h3>
          )}
          <button
            className={this.state.pacientConnected ? "" : "disabled-btn"}
            disabled={!this.state.pacientConnected}
            onClick={() => {
              this.setState({ callStarted: true });
              this.startTimer();
              this.startCall();
            }}
          >
            Začni klic
          </button>
        </>
      );
    } else {
      return (
        <>
          <h3>Vaš trenutni brksalnik je: {browserName}</h3>
          <h3>
            Prosimo, uporabite enega od naslednjih brskalnikov: Safari, Firefox,
            Chrome ali Opero.
          </h3>
        </>
      );
    }
  };

  rednerWelcomeScreen = () => {
    return (
      <div className="videoWelcomeScreen">
        <div id="logo">
          <img src={logoNewWhite}></img>
        </div>
        <img src={doctorIntro}></img>

        {this.checkBrowserType()}
      </div>
    );
  };

  renderFeedback = () => {
    return (
      <div className="videoWelcomeScreen">
        <img src={doctorIntro}></img>
        <h1>
          {doctorFName} {doctorLName} vas bo zdaj videl.
        </h1>
        <h3>Pritisnite gumb "Začni klic", da začnete video posvet.</h3>
        <button
          onClick={() => {
            this.setState({ callStarted: true });
            this.startCall();
          }}
        >
          Začni klic
        </button>
      </div>
    );
  };

  render() {
    return (
      <div className="App">
        {this.state.callStarted
          ? this.renderVideo()
          : this.rednerWelcomeScreen()}
      </div>
    );
  }
}

export default Video;
