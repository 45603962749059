import React from 'react';

import axios from 'axios';
import list from '../../../zavarovanci.json'

// const arrayToPush = [];

// list.map((item, i) => {
// 	arrayToPush.push({
// 			taxNumber : item,
// 			policyNumber : item,
// 			policyType : "Insurance",
// 			endDate : "2022-01-01T00:00:00.000Z",
// 			policyIssuer : "Merkur zavarovalnica d.d."

// 	})
// })


// const onSend = () => {
// 	arrayToPush.map((item, i) => {
// 		if(i == 499) {
// 			const sliced = arrayToPush.splice(0,499);
// 			axios.post('https://merkur.nyd.care/api/policies/multiple',{
// 				multiplePolicies: sliced
// 			})
// 			console.log(arrayToPush);
// 		}
// 	})
// }
// console.log(arrayToPush);



export default function SplosniPogojiUporabe() {
	import('../App.css');

  return (
    <div className="containrforPrivacyPolicy">

<p style={{ marginBottom: "10px"  }} style={{textAlign: "center", marginTop: "50px", marginBottom: "50px"}}
// onClick={() => onSend()}
 ><strong>SPLOŠNI POGOJI UPORABE SPLETNE STRANI </strong></p>

<strong> </strong>
<p style={{ marginBottom: "10px"  }}>
Ti Splošni pogoji uporabe spletne strani (»Splošni pogoji uporabe«) urejajo razmerje med vami in NYD d.o.o., z registriranim naslovom in poslovnim naslovom na Prešernova cesta 19, 1000 Ljubljana (»mi«, »nas«, »NYD«) v zvezi z uporabo spletne strani vseh morebitnih podstrani te spletne strani (»Spletna stran«).
</p>
<p style={{ marginBottom: "10px"  }}>
Poleg Splošnih pogojev uporabe spletne strani naprošamo, da se seznanite tudi s Politiko zasebnosti, ki opredeljuje način zbiranja, uporabo in namene zbiranja vaših podatkov, in s Politiko uporabe piškotkov, ki razkriva katere piškotke zbiramo ter za kakšne namene.
</p>
<p style={{ marginBottom: "10px"  }}>
Z DOSTOPANJEM DO SPLETNE STRANI IN NJENO UPORABO SOGLAŠATE S TEMI SPLOŠNIMI POGOJI UPORABE, KI VAS ZAVEZUJEJO OB VSAKOKRATNI UPORABI SPLETNE STRANI. V KOLIKOR S TEMI SPLOŠNIMI POGOJI UPORABE NE SOGLAŠATE, DO SPLETNE STRANI NE DOSTOPAJTE OZIROMA JE NE UPORABLJAJTE.
</p>
<p style={{ marginBottom: "10px"  }}>
Dr Merkur je storitev za uporabnike spletnega mesta (v nadaljevanju: »uporabnik«) kot SaaS posredniška storitev, ki omogoča rezervacijo terminov, komunikacijo z uporabniki preko elektronske pošte ali SMS obvestil. Dr Merkur je posredniška storitev med vami kot uporabnikom (»vi«, »vas«,« uporabnik«) in ponudnikom oziroma izvajalcem zdravstvenih storitev, zato NYD ne bi smeli obravnavati kot ponudnika zdravstvenih storitev. Sporazum med vami in NYD d.o.o. je torej strogo pogodba o storitvah, ki se nanaša na zagotavljanje tehnične rešitve. NYD d.o.o. in Merkur zavarovalnica d.d. kot naročnik, ima sklenjeno pogodbo o poslovnem sodelovanju za omogočanje storitve Dr Merkur, ki omogoča virtualno oskrbo uporabnikov z zvočno in/ali video obravnavo s strani zdravnika specialista splošne oz. družinske medicine (zdravstvena storitev, ki se izvaja v sklopu uporabe spletne strani),podpora različnim tipom možnih zaključkov obravnave v sklopu zdravstvene storitve, skladno s politiko Merkur zavarovalnica d.d. storitve,možnost oddaje mnenja in ocene zadovoljstva z opravljeno zdravstveno storitvijo.NYD d.o.o. in Merkur zavarovalnica d.d.nista in ne moreta biti odgovorna za vrsto ali kakovost nasvetov, pregledov ali drugih zdravstvenih storitev, ki/čese zagotavljajo v okviru video sestanka oziroma obravnave.
</p>

<ol style={{ marginTop: "45px", marginBottom: "15px"  }}>
 	<li><strong> 1. Vsebina Spletne strani in storitve</strong></li>
</ol>
<p style={{ marginBottom: "10px"  }}>
Namen Spletne strani je zagotoviti informacije o in v zvezi z NYD in storitvami, ki jih NYD zagotavlja. Vsa na Spletni strani objavljena vsebina in podajanje nasvetov so zgolj informativnega značaja in ne predstavlja zdravniškega nasveta in/ali dejavnosti
</p>

<p style={{ marginBottom: "10px"  }}>
Navkljub skrbi za pravilnost, ažurnost in celovitost vsebine Spletne strani, se občasni nepravilnosti, zastarelosti ali pomanjklivosti informacij, objavljenih na Spletni strani ne gre izogniti. NYD ne prevzema odgovornosti za škodo, nastalo zaradi objave ali kot posledica uporabe takih nepravilnih, zastarelih ali pomanjkljivih informacij. NYD ne jamči nemotenega delovanja in dostopanja do Spletne strani ter ne prevzema odgovornosti za škodo nastalo kot posledica oviranega delovanja oziroma nezmožnosti dostopanja do Spletne strani. Informacije, objavljene na Spletni strani, so izključno informativne narave. NYD ne prevzema nobene odgovornosti, da so te informacije popolne, vsebinsko pravilne in sveže.
</p>

<p style={{ marginBottom: "10px"  }}>
Z namenom kar najbolj sprotnega posodabljanja vsebine Spletne strani si NYD pridržuje pravico kadarkoli spremeniti in/ali posodobiti vsebino Spletne strani. Prav tako si NYD pridržuje pravico kadarkoli s spleta popolnoma odstraniti Spletno stran in/ali vsem uporabnikom onemogočiti njeno uporabo.
</p>

<p style={{ marginBottom: "10px"  }}>
Vsebina, ki jo NYD objavlja na Spletni strani in v obliki nasvetov preko Spletne stranii, naj bi štela kot dopolnilo medicinskim napotkom zdravnika. Uporaba Spletne strani in storitev je zgolj nadomestek tradicionalnega zdravstvenega varstva in je namenjena kot dodatno orodje zdravnikom, ki uporabnikom zagotavljajo učinkovito zdravstveno varstvo. NYD v nobenem pogledu ne prevzema odgovornosti kot izvajalec zdravstvene dejavnosti.
</p>
<p style={{ marginBottom: "10px"  }}>
Če zdravnik, zdravstveni delavec oziroma partner na podlagi informacij, prejetih s strani uporabnika, oceni, da bi lahko šlo za neposredno življenjsko ogroženost ali potrebo po nujni medicinski pomoči, usmeri uporabnika na pristojno zdravstveno službo ali na telefonsko številko 112. Uporabnik  je v takšnem primeru v celoti sam odgovoren za ustrezno ukrepanje. Za strokovnost in kakovost nudenja nasveta uporabniku je v celoti odgovoren zdravstveni delavec. Nasveti in storitve temeljijo na postavljenem vprašanju uporabnika in informacijah o simptomih, ki jih uporabnik v povezavi z težavo navede. Tako opravljeni nasveti ne predstavljajo postavitve diagnoze ali zdravniškega mnenja in dejavnosti.

</p>

<p style={{ marginBottom: "10px"  }}>
NYD zagotovalja uporabniku uporabo storitev ob podpisu pristopnega obrazca k storitvi (v nadaljevanju: »Pristopni obrazec«) in ob izpolnjevanju obveznosti teh Splošnihpogojev. NYD ponuja spletno aplikacijo, do katere se lahko dostopa preko internetnepovezave s komercialno dosegljivimi napravami (računalniki, mobilnimi telefoni, tablicami) inprogrami (spletni brskalniki). NYD ni dolžan zagotavljati naprav, programov in internetnegadostopa, ki so potrebni za uporabo storitev.

</p>

<ol style={{ marginTop: "45px", marginBottom: "15px"  }} start="2">
 	<li><strong> 2. Dostop do in uporaba Spletne strani</strong></li>
</ol>
<p style={{ marginBottom: "10px"  }}>
Dostop do in uporaba Spletne strani je dopustna le v skladu in pod pogoji, ki jih določajo ti Splošni pogoji uporabe,naša politika zasebnosti, politika uporabe piškotkov in druge relevantne določbe uporabnega prava.
</p>

<p style={{ marginBottom: "10px"  }}>
Z dostopom do in uporabo Spletne strani jamčite, da (i) način vaše uporabe Spletne strani ne bo škodoval Spletni strani, drugim obiskovalcem Spletne strani ali tretjim osebam, (ii) ne boste poskušali pridobiti neavtoriziranega dostopa do spletne strani, (iii) ne boste poskušali preslikati Spletne strani na katerokoli drugo spletno stran, (iv) boste do Spletne strani dostopali le prek vmesnika zagotovljenega z naše strani, (v) ne boste uporabljali programske opreme, ki omogoča pridobivanje informacij o uporabi Spletne strani s strani drugih obiskovalcev ter ugotavljanje njihove identitete, (vi) Spletne strani ne boste uporabljali v nasprotju s temi Splošnimi pogoji uporabe in drugimi relevantnimi določbami uporabnega prava.
</p>
<ol style={{ marginTop: "45px", marginBottom: "15px"  }} start="3">
 	<li><strong> 3. Vsebina uporabnikov/strank</strong></li>
</ol>
<p style={{ marginBottom: "10px"  }}>
Vsa vsebina, ki jo posredujete NYD, vključno z informacijami in gradivom posredovanim v okviru vaših vprašanj, odgovorov, prošenj za posredovanje informacij in komentarjev (v nadaljevanju “Vsebina uporabnika”) je vaša izključna odgovornosti in za njo ne odgovarjamo. Uporabnik je izključno odgovoren za podatke, ki jih posreduje v aplikaciji ali prek nje oziroma preko video klica in posledično prevzema vso odgovornost za svojo vsebino.
</p>

<p style={{ marginBottom: "10px"  }}>
Soglašate in potrjujete, da ste v celoti odgovorni za vso vsebino, ki nam jo posredujete. NYD ne nadzira Vsebine uporabnikov in ne jamči za njeno natančnost, celovitost in kakovost. Soglašate da NYD ni odgovoren za kakršno koli izgubo ali škodo, ki bi jo lahko imelo vaše zanašanje na te informacije.
</p>

<p style={{ marginBottom: "10px"  }}>
O domnevnih kršitvah v povezavi z Vsebino uporabnika nas prosimo obvestite na elektronski naslov: <a href="mailto:info@nyd.care">info@nyd.care</a>. Vsebina, ki nam jo posredujete bo prebrana, uporabljena in zbrana samo s stran NYD ali s strani NYD pooblaščenih oseb.
</p>
<ol style={{ marginTop: "45px", marginBottom: "15px"  }} start="4">
 	<li><strong> 4. Povezave do spletnih strani tretjih oseb</strong></li>
</ol>
<p style={{ marginBottom: "10px"  }}>
Spletna stran lahko, z namenom olajšanja iskanja in izboljšanja uporabniške izkušnje, vsebuje hiperpovezave do spletnih strani tretjih oseb. Sama objava hiperpovezave na Spletni strani ne predstavlja, niti ne nakazuje na kakršnokoli obliko podpore, sponzorstva ali priporočila s hiperpovezavo dostopane spletne strani, njene vsebine ali lastnika, s strani NYD.
</p>
S klikom na hiperpovezavo boste zapustili to Spletno stran in bili preusmerjeni na spletno stran tretje osebe za katero veljajo drugi splošni pogoji uporabe in politika zasebnosti.


<p style={{ marginBottom: "10px"  }}>
NYD ne prevzema odgovornosti za tako dostopane spletne strani tretjih oseb, za vsebino in informacije objavljene na njih, niti ne prevzema odgovornosti za uspešno dostopanje do tovrstnih spletnih strani.
</p>
<ol style={{ marginTop: "45px", marginBottom: "15px"  }} start="5">
 	<li><strong> 5. Podatki o oceni o opravljeni storitvi</strong></li>
</ol>

<p style={{ marginBottom: "10px"  }}>
V primeru,  da se uporabnik eksplicitno strinja (aktivno odda strinjanje) ob zaključku procesa oddaje Pristopnega obrazeca oziroma povpraševanja za samoplačniško zdravstveno storitev ali sorodnega procesa na spletni strani, lahko uporabnika pozovemo k oddaji ocene oz. mnenja o opravljeni storitvi po tem, ko bo storitev zaključena. V ta namen lahko skladno s temi splošnimi pogoji in politiko zasebnosti hranimo uporabnikov elektronski naslov, termin (v kolikor je ta na voljo) in izbranega zdravstvenega sodelavca za namene analize o zadovoljstvu po opravljeni storitvi.
</p>
<p style={{ marginBottom: "10px"  }}>
Za namen oddaje mnenja in ocene po opravljeni storitvi uporabnik spletnega mesta s potrditvijo Splošnih pogojev potrjuje, da je seznanjen s temi splošnimi pogoji in politiko zasebnosti. Uporabnik spletnega mesta se prostovoljno odloči, ali bo na vprašanja odgovarjal in podal mnenje, ali ne. Za namen pošiljanja vprašalnika uporabniku po elektronski pošti izvajalec obdeluje osebne podatke posameznika, in sicer njegov elektronski naslov. V primeru odgovarjanja na vprašanja in oddaje mnenja in ocene po opravljeni storitvi izvajalec obdeluje elektronski naslov uporabnika, termin storitve (če je podatek na voljo) in informacije o zdravstveni storitvi, ki jo je uporabnik koristil. V primeru obdelave posebnih vrst osebnih podatkov za to pridobimo predhodno privolitev posameznika v skladu z veljavnimi predpisi s področja varstva osebnih podatkov. Po izpolnjenem in vrnjenem vprašalniku uporabnika se ti podatki izbrišejo, saj je bil namen uporabe dosežen in niso več potrebni za nadaljnjo obdelavo.
</p>
<ol style={{ marginTop: "45px", marginBottom: "15px"  }} start="6">
 	<li><strong> 6. Storitev zagotavljanja komunikacije uporabnikom</strong></li>
</ol>

<p style={{ marginBottom: "10px"  }}>
Storitvi elektronskega obveščanja prek sporočil SMS in elektronske pošte se izvajata prek spletne aplikacije ali vmesnika, ki ga ponuja izvajalčev podizvajalec. Pred obveščanjem oziroma v sklopu Dr. Merkur storitev za pošiljanja obvestil npr. obvestila o terminihbo NYDod uporabnika kot prejemnika pridobil jasno, nedvoumno in s Splošno uredbo o varstvu podatkov ter vso relevantno zakonodajo skladno privolitev za prejem tovrstnih sporočil. Podlaga za sporočilo je poleg Splošne uredbe o varstvu podatkov tudi Zakon o elektronskih komunikacijah (ZEKom-1).Sporočila SMS in elektronska pošta so posredovani v zunanji sistem,v skladu s politiko zasebnosti.
</p>
<ol style={{ marginTop: "45px", marginBottom: "15px"  }} start="7">
 	<li><strong> 7. Pravice intelektualne lastnine</strong></li>
</ol>
<p style={{ marginBottom: "10px"  }}>
Spletna stran in njena vsebina sta intelektualna lastnina NYD oziroma njenih avtorjev ter se ne sme, razen na način določen v Splošnih pogojih uporabe, uporabljati brez izrecnega pisnega soglasja NYD. NYD, NYD.care, logo, vse slike, fotografije in besedila ter vsa vsebina spletne strani, grafike in ikone gumbov predstavljajo intelektualno lastnino NYD. Vse ostale znamke, imena produktov in imena družb ali logotipi so last njihovih lastnikov. Razen v primerih, jasno določenih na Spletni strani, je vsebino Spletne strani prepovedano kopirati, tiskati, prenašati, prikazovati, prodajati, objavljati, shranjevati, spreminjati  in uporabljati v komercialne namene brez predhodnega pisnega soglasja NYD.
</p>

<p style={{ marginBottom: "10px"  }}>
Spletna stran in njena vsebina sta intelektualna lastnina Merkur zavarovalnica d.d. oziroma njenih avtorjev ter se ne sme, razen na način določen v Splošnih pogojih uporabe, uporabljati brez izrecnega pisnega soglasja Merkur zavarovalnice d.d. Merkur zavarovalnica d.d., Merkur, Dr. Merkur, logo, ter vsebina spletne strani predstavljajo intelektualno lastnino Merkur zavarovalnica d.d.. Vse ostale znamke, imena produktov in imena družb ali logotipi so last njihovih lastnikov. Razen v primerih, jasno določenih na Spletni strani, je vsebino Spletne strani prepovedano kopirati, tiskati, prenašati, prikazovati, prodajati, objavljati, shranjevati, spreminjati  in uporabljati v komercialne namene brez predhodnega pisnega soglasja NYD.
</p>
<p style={{ marginBottom: "10px"  }}>
Logotipi, imena družb, blagovne znamke in druge znamke, ki so prikazane na Spletni strani, so lahko zaščitene znamke in so, bodisi v lasti NYD ali Merkur zavarovalnice d.d. , bodisi objavljene s soglasjem nosilca industrijskih pravic na teh znamkah. Njihova uporaba je brez predhodnega pisnega soglasja NYD ali tretjih oseb prepovedana.
</p>
<ol style={{ marginTop: "45px", marginBottom: "15px"  }} start="8">
 	<li><strong> 8. Izključitev odgovornosti</strong></li>
</ol>
<p style={{ marginBottom: "10px"  }}>
Razen, če je eksplicitno določeno v teh Splošnih pogojih uporabe, NYD ne daje nobenih zavez in izključuje vsa jamstva, eksplicitna ali implicitna, v največjem obsegu možnem v skladu z veljavno zakonodajo glede ustreznosti, zanesljivosti, razpoložljivosti, pravočasnosti in točnosti informacij ter vsebine spletne strani in povezav do spletnih strani tretjih na naši spletni strani. Spletna stran, informacije in vsebina na njej ter s spletno stranjo povezane grafike so posredovane brez kakršnega koli jamstva s strani NYD.
</p>

<p style={{ marginBottom: "10px"  }}>
Podatki, ki so vsebovani na spletni strani so lahko netočni ali imajo tipkarske napake. NYD ne odgovarja za škodo zaradi možnosti izgube uporabe spletne strani, podatkov, dobička, dobrega imena ali ugleda, telesnih poškodb ali katerih koli drugih škod, ki izvirajo iz (i) vašega dostopanja do naše spletne strani, (ii) vaše nezmožnosti uporabe spletne strani, (iii) kakršnekoli informacije ali vsebine, vsebovane na naši spletni strani, (iv) vaše informacije ali gradiva posredovanega na našo spletno stran. Izključitev odgovornosti velja ne glede na to, ali je škoda nastala na podlagi kršitve pogodbe, odškodninske odgovornosti, malomarnosti, objektivne odgovornosti ali na drug način.
</p>
<ol style={{ marginTop: "45px", marginBottom: "15px"  }} start="9">
 	<li><strong> 9. Varstvo osebnih podatkov</strong></li>
</ol>
<p style={{ marginBottom: "10px"  }}>
NYD zbrane osebne podatke obdeluje skladno s <em>politiko zasebnosti</em> in <em>politiko uporabe piškotkov</em>.
</p>
<ol style={{ marginTop: "45px", marginBottom: "15px"  }} start="10">
 	<li><strong> 10. Sprememba Splošnih pogojev uporabe</strong></li>
</ol>
<p style={{ marginBottom: "10px"  }}>
NYD si pridržuje pravico kadarkoli spremeniti vsebino teh Splošnih pogojev uporabe. Spremenjeni Splošni pogoji uporabe urejajo dostop do in uporabo Spletne strani od trenutka objave na Spletni strani. Z dostopanjem in uporabo Spletne strani po uveljavitvi spremenjenih Splošnih pogojev uporabe tako soglašate k uporabi takšnih spremenjenih Splošnih pogojev uporabe. Datum zadnje spremembe Splošnih pogojev uporabe je objavljen spodaj.
</p>
<ol style={{ marginTop: "45px", marginBottom: "15px"  }} start="11">
 	<li><strong> 11. Končne določbe</strong></li>
</ol>
<p style={{ marginBottom: "10px"  }}>
Ti Splošni pogoji uporabe, skupaj s Politiko zasebnosti in Politiko uporabe piškotkov, objavljenima na Spletni strani, celovito urejajo razmerje med vami in NYD v zvezi z vašo uporabo Spletne strani.
</p>
<p style={{ marginBottom: "10px"  }}>
Za presojo teh Splošnih pogojev uporabe se uporablja slovensko pravo. V primeru spora, ki izhaja iz in je v zvezi s temi Splošnimi pogoji uporabe je pristojno stvarno pristojno sodišče v Ljubljani.
</p>
<ol style={{ marginTop: "45px", marginBottom: "15px"  }} start="12">
 	<li><strong> 12. Kontakt</strong></li>
</ol>
<p style={{ marginBottom: "10px"  }}>
V primeru kakršnihkoli vprašanj nas prosim kontaktirajte na info@nyd.care.
</p>
<p style={{ marginBottom: "10px"  }}>
<sup>© </sup>NYD d.o.o., Ljubljana marec 2021.
</p>



    </div>
  );
}
