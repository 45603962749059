import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";


import Voice from "./components/voice";
import Video from "./components/video";

// IMPORTS FOR MERKUR
import QuestionaryMerkur from "./components/merkur/questionary";
import FeedbackMerkur from "./components/merkur/feedback";
import DoctorFeedbackMerkur from "./components/merkur/feedbackDoctor";
import VideoisCanceledMerkur from './components/merkur/VideoisCanceled';
import PolitikaZasebnostiMerkur from "./components/merkur/legal/PolitikaZasebnosti"
import SplosniPogojiUporabeMerkur from "./components/merkur/legal/SplosniPogojiUporabe"
//END IMPORTS FOR MERKUR


//IMPORTS FOR MSD
import QuestionaryMSD from "./components/msd/questionary";
import FeedbackMSD from "./components/msd/feedback";
import DoctorFeedbackMSD from "./components/msd/feedbackDoctor";
import VideoisCanceledMSD from './components/msd/VideoisCanceled';
import PolitikaZasebnostiMSD from "./components/msd/legal/PolitikaZasebnosti"
import SplosniPogojiUporabeMSD from "./components/msd/legal/SplosniPogojiUporabe"
//END IMPORTS FOR MSD


//IMPORTS FOR NYD
import QuestionaryNYD from "./components/nyd/questionary";
import FeedbackNYD from "./components/nyd/feedback";
import DoctorFeedbackNYD from "./components/nyd/feedbackDoctor";
import VideoisCanceledNYD from './components/nyd/VideoisCanceled';
import PolitikaZasebnostiNYD from "./components/nyd/legal/PolitikaZasebnosti"
import SplosniPogojiUporabeNYD from "./components/nyd/legal/SplosniPogojiUporabe"
//END IMPORTS FOR NYD

export default class App extends React.Component {
  constructor(props) {
    super(props);
  }

   appChecker(){
         
    // eslint-disable-next-line no-lone-blocks
    if(process.env.REACT_APP_PROJECT === 'Merkur') {
      return (
      <>
      <Route exact path="/" component={QuestionaryMerkur}></Route>
      <Route exact path="/questionary" component={QuestionaryMerkur}></Route>
      <Route exact path="/politika-zasebnosti" component={PolitikaZasebnostiMerkur}></Route>
      <Route exact path="/splosni-pogoji-uporabe" component={SplosniPogojiUporabeMerkur}></Route>
      <Route exact path="/feedback" component={FeedbackMerkur}></Route>
      <Route exact path="/doctoroppinion" component={DoctorFeedbackMerkur}></Route>
      <Route exact path="/canceled" component={VideoisCanceledMerkur}></Route>
      <Route exact path="/voice" component={Voice}></Route>
      <Route exact path="/video" component={Video}></Route>
      <Route exact path="/voice" component={Voice}></Route>
      </>)
    } else if(process.env.REACT_APP_PROJECT === "MSD") {
      return (
        <>
        <Route exact path="/" component={QuestionaryMSD}></Route>
        <Route exact path="/questionary" component={QuestionaryMSD}></Route>
        <Route exact path="/politika-zasebnosti" component={PolitikaZasebnostiMSD}></Route>
        <Route exact path="/splosni-pogoji-uporabe" component={SplosniPogojiUporabeMSD}></Route>
        <Route exact path="/feedback" component={FeedbackMSD}></Route>
        <Route exact path="/doctoroppinion" component={DoctorFeedbackMSD}></Route>
        <Route exact path="/canceled" component={VideoisCanceledMSD}></Route>
        <Route exact path="/video" component={Video}></Route>
        <Route exact path="/voice" component={Voice}></Route>
        </>
      )
    } else if (process.env.REACT_APP_PROJECT === "NYD"){
      return (
        <>
        <Route exact path="/" component={QuestionaryNYD}></Route>
        <Route exact path="/questionary" component={QuestionaryNYD}></Route>
        <Route exact path="/politika-zasebnosti" component={PolitikaZasebnostiNYD}></Route>
        <Route exact path="/splosni-pogoji-uporabe" component={SplosniPogojiUporabeNYD}></Route>
        <Route exact path="/feedback" component={FeedbackNYD}></Route>
        <Route exact path="/doctoroppinion" component={DoctorFeedbackNYD}></Route>
        <Route exact path="/canceled" component={VideoisCanceledNYD}></Route>
        <Route exact path="/video" component={Video}></Route>
        <Route exact path="/voice" component={Voice}></Route>
        </>
      )
    }
  }

  render() {
    console.log("ROUTER PROPS", this.props);

    return (
      <Router>
        <Switch>
          {/* main routes */}
          {console.log(process.env.REACT_APP_PROJECT)}
          {this.appChecker()}
           <Route path='*' exact>
            <Redirect path="/"></Redirect>
          </Route>
        </Switch>
      </Router>
    );
  }
}
