import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import axios from "axios";
//images

import grozno_positive from "../../assets/rating/grozno.svg";
import slab_positive from "../../assets/rating/slab.svg";
import povprecno_positive from "../../assets/rating/povprecno.svg";
import dobro_positive from "../../assets/rating/dobro.svg";
import odlicno_positive from "../../assets/rating/odlicno.svg";
import grozno_negativ from "../../assets/grozno_negativ.svg";
import slab_negativ from "../../assets/slab_negativ.svg";
import povprecno_negativ from "../../assets/povprecno_negativ.svg";
import dobro_negativ from "../../assets/dobro_negativ.svg";
import odlicno_negativ from "../../assets/odlicno_negativ.svg";

import specializations from "../../list.json";
import { set } from "date-fns/esm";

//Dinamic Images
const feedback_success =
  require(`../../assets/${process.env.REACT_APP_PROJECT}/feedback_success.png`).default;
const merkurLogo =
  require(`../../assets/${process.env.REACT_APP_PROJECT}/merkur.svg`).default;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    padding: "1em 5em",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  padded: {
    padding: "3em 15em 2em 15em",
    background: "#A6D586",
  },
}));

export default function HorizontalLinearStepper({ ...props }) {
  import("./App.css");

  console.log(props);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  // The first commit of Material-UI

  const [state, setState] = React.useState({
    grozno: true,
    slab: true,
    povprecno: true,
    dobro: true,
    odlicno: true,
    vsebina: false,
    prijaznost: false,
    odnos: false,
    strokovnost: false,
    enostavnost: false,
    hitrost: false,
    drugo: "",
    placevanje: false,
    fiveEuros: false,
    tenEuros: false,
    twentyEuros: false,
    opinnionForPacient: "",
    oppinionForInsurance: "",
    forSpecialist: "",
  });

  function getSteps() {
    return ["Prijava"];
  }

  const {
    vsebina,
    prijaznost,
    odnos,
    strokovnost,
    enostavnost,
    hitrost,
    drugo,
    placevanje,
    fiveEuros,
    tenEuros,
    twentyEuros,
    willingToPay,
    ratingOptions,
    rating,
    opinnionForPacient,
    forSpecialist,
    oppinionForInsurance,
  } = state;

  const handleSelection = (event) => {
    console.log(event);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div className="feedbackForm">
            <h1>Video posvet je končan</h1>

            <form className="doctorOppinionform">
              <h2>Mnenje za pacienta</h2>
              <TextareaAutosize
                aria-label="minimum height"
                rowsMin={6}
                placeholder="Napišite vaše mnenje za pacienta...."
                style={{
                  width: "90%",
                  border: "1px solid #A6A6A6",
                  borderRadius: "8px",
                }}
                onChange={(e) => {
                  setState({ ...state, opinnionForPacient: e.target.value });
                }}
              />

              <h2>Napotitev k specialistu</h2>
              <input
                id="country"
                name="country"
                required
                list="country-list"
                onChange={(e) => {
                  const verify = specializations.filter(
                    (item) => item == e.target.value
                  );
                  if (verify == e.target.value) {
                    setState({ ...state, forSpecialist: e.target.value });
                  } else {
                    setState({ ...state, forSpecialist: undefined });
                  }
                }}
                title="Please select a country"
                style={{ width: "96%", padding: "0.5em" }}
              />
              <datalist id="country-list" style={{ width: "96%" }}>
                {specializations.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </datalist>

              <h2>Mnenje za zavarovalnico</h2>
              <TextareaAutosize
                aria-label="minimum height"
                rowsMin={6}
                placeholder="Napišite vaše mnenje za pacienta...."
                style={{
                  width: "90%",
                  border: "1px solid #A6A6A6",
                  borderRadius: "8px",
                }}
                onChange={(e) => {
                  setState({ ...state, oppinionForInsurance: e.target.value });
                }}
              />
            </form>
          </div>
        );
      default:
        return "Unknown step";
    }
  }

  const isStepOptional = (step) => {};

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  // Goes one step forward
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  // Goes one step back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Sends survey to database
  const sendSurvey = (state, props) => {
    console.log("Update survey with survey id", props);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}sessions/${props.location.state.surveyId}`,  {
          headers: {
            'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
          }
        }
      )
      .then((response) => {
        console.log(response);
        axios.patch(
          `${process.env.REACT_APP_API_URL}sessions/${props.location.state.surveyId}`,  
          {
            id: props.location.state.surveyId,
            diagnosis: opinnionForPacient,
            nextStep: forSpecialist,
            oppinionForInsurance: oppinionForInsurance,
            callStatus: "Completed",
          },{
            headers: {
              'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
            }
          }
        );
      });
  };

  return (
    <div id="doctorOpinion">
      <div className={classes.root} id="feedbackRoot">
        <div id="logo" className="logo-relative-mobile">
          <img src={merkurLogo}></img>
        </div>
        {console.log("The end State", state, process.env.API_URL)}
        {/* <Stepper
        classes={{ root: classes.padded }}
        alternativeLabel
        activeStep={activeStep}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper> */}
        <div className="feedbackView">
          {activeStep === steps.length ? (
            <div className="feedbackFormCentered">
              <h1>Video posvet je končan</h1>
              <h2>Hvala za vaše mnenje</h2>
              <img
                className="imageResponsive"
                style={{ height: "55%", width: "auto" }}
                src={feedback_success}
              ></img>
            </div>
          ) : (
            <div className="stepBoxFeedback">
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div className="stepBoxControllFeedback">
                {activeStep === 0 ||
                (activeStep === steps.length - 1) |
                  (activeStep === steps.length - 2) ? (
                  <></>
                ) : (
                  <Button
                    disabled={
                      activeStep === 0 ||
                      activeStep === steps.length - 1 ||
                      activeStep === steps.length - 2
                    }
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Nazaj
                  </Button>
                )}
                {isStepOptional(activeStep) && (
                  <Button onClick={handleSkip} className={classes.button}>
                    Skip
                  </Button>
                )}

                {activeStep === steps.length - 1 ? (
                  <Button
                    disabled={
                      opinnionForPacient.length <= 3 ||
                      forSpecialist == undefined ||
                      forSpecialist.length <= 3
                        ? true
                        : false
                    }
                    onClick={() => {
                      handleNext();
                      sendSurvey(state, props);
                    }}
                    style={
                      opinnionForPacient.length <= 3 ||
                      forSpecialist == undefined ||
                      forSpecialist.length <= 3
                        ? { color: "#000", background: "#efefef" }
                        : { background: "#5cc8bd" }
                    }
                    className={classes.button}
                    id="blueButton"
                  >
                    Potrdi
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleNext();
                    }}
                    className={classes.button}
                    id="blueButton"
                  >
                    Naprej
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
