import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import "intl-tel-input/build/css/intlTelInput.css";
import "react-phone-number-input/style.css";
import doctorIntro from "../../assets/NYD/imagesquestionary/doctorIntroNYD.png";
import "react-dropzone-uploader/dist/styles.css";

import axios from "axios";
import moment from "moment";

import dateLeft from "../../assets/dateLeft.svg";
import dateLeftBlack from "../../assets/dateLeftBlack.svg";
import dateRight from "../../assets/dateRight.svg";
import { Grid } from "@material-ui/core";

//Dinamic Images
const registration_success =
  require(`../../assets/${process.env.REACT_APP_PROJECT}/registration_success.svg`).default;


  const urlName = new URLSearchParams(window.location.search);
  const namefromurl = urlName.get("name");

  const urlLname = new URLSearchParams(window.location.search);
  const lnamefromurl = urlLname.get("lastname");

  const urlid = new URLSearchParams(window.location.search);
  const idfromurl = urlid.get("id");
  
  const urlemail = new URLSearchParams(window.location.search);
  const emailfromurl = urlemail.get("email");

console.log( "This is data from Url :", namefromurl, lnamefromurl, idfromurl, emailfromurl );


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    padding: "1em 5em",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  padded: {
    padding: "3em 15em 2em 15em",
  },
}));

// Querying URL params
const queryParams = new URLSearchParams(window.location.search);
let uniqueCode = queryParams.get("uniqueCode");

let patientId = queryParams.get("pacientId");
let ackitId = queryParams.get("ackitId");
let uniqueCodeInput = "";

export default function HorizontalLinearStepper() {
  import("./App.css");

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  // The first commit of Material-UI

  const [state, setState] = React.useState({
    dateCounter: 0,
    availableSlots: [],
    filteredCurrentDay: [],
    filteredSecondDay: [],
    filteredThirdDay: [],
    davcna: "",
    taxNumberValid: false,
    sprejemamPogoje: false,
    sprejemamPogojeDva: false,
    sprejemamPogojeTri: false,
    validState: "",
    validEmail: true,
    validPhone: true,
    sessionData: [],
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "070",
    birthday: "",
    firstQuestion: "",
    secondQuestion: "",
    thirdQuestion: "",
    fourthQuestion: "",
    fifthQuestion: "",
    painLevel: undefined,
    moski: false,
    zenski: false,
    selectedTimeSlot: "",
    selectedTimeSlotOld: "",
    selectedDoctor: "",
    policyNumber: "",
    emailInput: "",
    phoneInput: "",
    checkboxes: new Array(11).fill().map((_, i) => !i),
    file: [],
    uniqeCodeValid: false,
    // uniqueCodeInput: "",
    agreeCheck: false,
  });

  useEffect(() => {
    getAvailableSlotsForThreeDays();
    ifUniqueCodeParamsExist();
    console.log("uniqueCode", uniqueCode);
  }, []);

  const ifUniqueCodeParamsExist = () => {
    if (uniqueCode === null) {
      return;
    } else {
      axios
        .get(`${process.env.REACT_APP_API_URL}policies/${uniqueCode}`,
        {
          headers: {
            'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
          }
        }
        )
        .then((res) => {
          if (uniqueCode === res.data.taxNumber) {
            setActiveStep(1);
          }
        });
    }
  };

  function getSteps() {
    return ["Izbira termina", "Zaključek"];
  }

  const agreeCheckboxHandler = () => {
    setState({ ...state, agreeCheck: !agreeCheck });
  };

  // Checking if uniqueCode(taxNumber) is present in database
  // const uniqueCodeValidator = (uniqueCodeInput) => {
  //   console.log(uniqueCodeInput, "INPUT");
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}policies/${uniqueCodeInput}`, {
  //       headers: {
  //         'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
  //       }
  //     })
  //     .then((res) => {
  //       console.log("TAX NUMBER", res.data.taxNumber);
  //       console.log("RES DATA", res);
  //       if (res.data.taxNumber === uniqueCodeInput) {
  //         setState({
  //           ...state,
  //           uniqueCodeValid: true,
  //         });
  //         uniqueCode = uniqueCodeInput;
  //       } else {
  //         setState({ ...state, uniqueCodeValid: false });
  //       }
  //     });

  //   console.log(uniqueCodeValid, "uniqueCodeValid");
  //   console.log(uniqueCodeInput, "uniqueCodeInput");
  // };

  const stepValidator = (step) => {
    switch (step) {
      case 0:
        return (
          <Button
            disabled={agreeCheck ? false  : true}
            // disabled={false} //temp
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.button}
            id="redButton"
          >
            {activeStep === steps.length - 1 ? "Potrdi" : "Naprej"}
          </Button>
        );
      case 1:
        return (
          <Button
            disabled={selectedTimeSlot.length < 1 ? true : false}
            // disabled={false} //temp
            variant="contained"
            color="primary"
            onClick={() => {
              handleNext();
              createSession(state);
            }}
            className={classes.button}
            id="redButton"
          >
            {activeStep === steps.length - 1 ? "Potrdi" : "Naprej"}
          </Button>
        );
    }
  };

  const createSession = async (state) => {
    console.log(state.birthday);

    await createAppointment();
    await deleteSelectedTimeSlot(selectedTimeSlot.id);
  };

  const createAppointment = async () => {
    console.log("UNIQUE CODE", uniqueCodeInput);
    axios
      .post(`${process.env.REACT_APP_API_URL}sessions/`, {
        pacientId: idfromurl, //salje prazan string
        doctorId: selectedTimeSlot.doctor,
        sessionDate: selectedTimeSlot.startTime,
        sessionStartTime: selectedTimeSlot.startTime,
        firstName: namefromurl,
        lastName: lnamefromurl,
        email: emailfromurl,
        options: {
          policyIssuer: "NYD",
          callType: "video",
        },
      },{
        headers: {
          'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
        }
      })
      .then((result) => {
        console.log("OVO JE REQUEST", result);
        var formData = new FormData();
        formData.append("id", result.data.id);
        file.map((item) => {
          formData.append("file", item);
        });
        updateSessionWithFiles(result, formData);
      })
      .catch((reason) => {
        console.log("request nije yupsei", reason);
      });
  };

  const updateSessionWithFiles = async (result, formData) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}sessions/${result.data.id}`,
        formData,
        {
          headers: { "Content-type": "multipart/form-data" },
        }
      )
      .then((response) => console.log("THIS IS THE UPDATE REQUEST", response));
  };

  const deleteSelectedTimeSlot = async (id) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}availabilitySlots/${id}`)
      .then((response) => console.log(response));
  };

  const {
    dateCounter,
    selectedTimeSlot,
    firstName,
    lastName,
    email,
    phoneNumber,
    birthday,
    painLevel,
    moski,
    policyNumber,
    file,
    agreeCheck,
    uniqueCodeValid,
    // uniqueCodeInput,
  } = state;

  const removeDuplicateObjectFromArray = (array, key) => {
    var check = new Set();
    return array.filter((obj) => !check.has(obj[key]) && check.add(obj[key]));
  };

  const getAvailableSlotsForThreeDays = async () => {
    axios(`${process.env.REACT_APP_API_URL}availabilitySlots/slots`).then(
      (response) => {
        var datePlusOne = new Date().setDate(new Date().getDate() + 1);
        var datePlusTwo = new Date().setDate(new Date().getDate() + 2);

        var firstDay = response.data.filter(
          (item) => new Date(item.startTime).getDate() === new Date().getDate()
        );
        var secondDay = response.data.filter(
          (item) =>
            new Date(item.startTime).getDate() ===
            new Date(datePlusOne).getDate()
        );
        var thirdDay = response.data.filter(
          (item) =>
            new Date(item.startTime).getDate() ===
            new Date(datePlusTwo).getDate()
        );
        var firstDayFiltered = removeDuplicateObjectFromArray(
          firstDay,
          "startTime"
        );
        var secondDayFiltered = removeDuplicateObjectFromArray(
          secondDay,
          "startTime"
        );
        var thirdDayFiltered = removeDuplicateObjectFromArray(
          thirdDay,
          "startTime"
        );

        firstDayFiltered.sort(
          (a, b) =>
            new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
        );
        secondDayFiltered.sort(
          (a, b) =>
            new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
        );
        thirdDayFiltered.sort(
          (a, b) =>
            new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
        );

        setState({
          ...state,
          availableSlots: response.data,
          filteredCurrentDay: firstDayFiltered,
          filteredSecondDay: secondDayFiltered,
          filteredThirdDay: thirdDayFiltered,
        });
      }
    );
  };

  const reserveSelectedTimeSlot = (id) => {
    console.log(
      "WE NEED THIS TO UPDATE: OLD",
      selectedTimeSlot.id,
      "New State",
      id
    );
    axios.patch(
      `${process.env.REACT_APP_API_URL}availabilitySlots/${selectedTimeSlot.id}`,
      {
        pending: "false",
      }
    );
    axios.patch(`${process.env.REACT_APP_API_URL}availabilitySlots/${id}`, {
      pending: "true",
    });
  };

  const renderDateTimeSlots = (dateCounter) => {
    switch (dateCounter) {
      case 0:
        return (
          <>
            {state.filteredCurrentDay.map((slot) => (
              <>
                {slot.pending == true ? (
                  <></>
                ) : (
                  <div
                    key={slot.id}
                    data-id={slot.doctor}
                    data-slot={slot.id}
                    data-pending={slot.pending}
                    className={`item ${
                      selectedTimeSlot.startTime === slot.startTime
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      reserveSelectedTimeSlot(slot.id);
                      setState({ ...state, selectedDoctor: slot.doctor });
                      setState({ ...state, selectedTimeSlot: slot });
                    }}
                  >
                    {console.log(
                      new Date(slot.startTime),
                      new Date(slot.startTime).getHours()
                    )}
                    {new Date(slot.startTime).getHours().toString().length <= 1
                      ? "0" + new Date(slot.startTime).getHours().toString()
                      : new Date(slot.startTime).getHours().toString()}
                    :
                    {new Date(slot.startTime).getMinutes().toString().length <=
                    1
                      ? new Date(slot.startTime).getMinutes().toString() + "0"
                      : new Date(slot.startTime).getMinutes().toString()}
                  </div>
                )}
              </>
            ))}
          </>
        );
        break;
      case 1:
        return (
          <>
            {state.filteredSecondDay.map((slot) => (
              <>
                {slot.pending == true ? (
                  <></>
                ) : (
                  <div
                    key={slot.id}
                    data-id={slot.doctor}
                    data-slot={slot.id}
                    className={`item ${
                      selectedTimeSlot.startTime === slot.startTime
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      reserveSelectedTimeSlot(slot.id);
                      setState({ ...state, selectedDoctor: slot.doctor });
                      setState({ ...state, selectedTimeSlot: slot });
                    }}
                  >
                    {console.log(
                      new Date(slot.startTime),
                      new Date(slot.startTime).getHours()
                    )}
                    {new Date(slot.startTime).getHours().toString().length <= 1
                      ? "0" + new Date(slot.startTime).getHours().toString()
                      : new Date(slot.startTime).getHours().toString()}
                    :
                    {new Date(slot.startTime).getMinutes().toString().length <=
                    1
                      ? new Date(slot.startTime).getMinutes().toString() + "0"
                      : new Date(slot.startTime).getMinutes().toString()}
                  </div>
                )}
              </>
            ))}
          </>
        );
        break;
      case 2:
        return (
          <>
            {state.filteredThirdDay.map((slot) => (
              <>
                {slot.pending == true ? (
                  <></>
                ) : (
                  <div
                    key={slot.id}
                    data-id={slot.doctor}
                    data-slot={slot.id}
                    className={`item ${
                      selectedTimeSlot.startTime === slot.startTime
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      reserveSelectedTimeSlot(slot.id);
                      setState({ ...state, selectedDoctor: slot.doctor });
                      setState({ ...state, selectedTimeSlot: slot });
                    }}
                  >
                    {console.log(new Date(slot.startTime))}
                    {new Date(slot.startTime).getHours().toString().length <= 1
                      ? "0" + new Date(slot.startTime).getHours().toString()
                      : new Date(slot.startTime).getHours().toString()}
                    :
                    {new Date(slot.startTime).getMinutes().toString().length <=
                    1
                      ? new Date(slot.startTime).getMinutes().toString() + "0"
                      : new Date(slot.startTime).getMinutes().toString()}
                  </div>
                )}
              </>
            ))}
          </>
        );
        break;
      default:
        return (
          <>
            {state.filteredCurrentDay.map((slot) => (
              <div
                key={slot.id}
                data-id={slot.doctor}
                data-slot={slot.id}
                className={`item ${
                  selectedTimeSlot === slot.startTime ? "selected" : ""
                }`}
                onClick={() => {
                  reserveSelectedTimeSlot(slot.id);
                  setState({ ...state, selectedTimeSlot: slot });
                }}
              >
                {new Date(slot.startTime).getHours().toString().length <= 1
                  ? "0" + new Date(slot.startTime).getHours().toString()
                  : new Date(slot.startTime).getHours().toString()}
                :
                {new Date(slot.startTime).getMinutes().toString().length <= 1
                  ? new Date(slot.startTime).getMinutes().toString() + "0"
                  : new Date(slot.startTime).getMinutes().toString()}
              </div>
            ))}
          </>
        );
    }
  };

  function getStepContent(step, state) {
    require("moment/locale/sl.js");

    switch (step) {
      case 0:
        return (
          <div className="davcnaForm">
            <h1>Video Posvet</h1>
            <p>
              Za prijavo na videoposvet z zdravnikom vas prosimo, da vnesete
              vašo unikatno kodo katero ste dobili na izvidu ali e-pošte.
            </p>
            <form className="form">
              <label style={{ padding: "2% 0 4% 0" }}>
               
                {/* <TextField
                  id="outlined-full-width"
                  placeholder="Unikatna koda"
                  fullWidth
                  margin="normal"
                  onChange={(e) => {
                    // setState({
                    //   ...state,
                    //   uniqueCodeInput: e.target.value,
                    // });
                    uniqueCodeInput = e.target.value;
                    // uniqueCodeValidator(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  // value="1223334444"
                /> */}
              </label>
              <div
                className="painLevel"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignContent: "center",
                  alignItems: "center",
                  margin: "0em 1em 0em 0em",
                }}
              >
                <input
                  onClick={agreeCheckboxHandler}
                  style={{ marginRight: "1em" }}
                  type="checkbox"
                  checked={agreeCheck}
                />
                <p className={"terms"}>
                  Sprejmi{" "}
                  <a
                    href="https://nyd.care/pravilnik-o-zasebnosti/"
                    target="_blank"
                  >
                    Politiko zasebnosti
                  </a>{" "}
                  in{" "}
                  <a
                    href="https://nyd.care/pravilnik-o-zasebnosti/"
                    target="_blank"
                  >
                    Varstvo podatkov
                  </a>{" "}
                </p>
              </div>
            </form>
          </div>
        );
      case 1:
        return (
          <div className="davcnaForm">
            <h1>Izbira termina</h1>
            <p>
              Prosimo vas, da izberete termin, ki vam ustreza, za videoposvet z
              zdravnikom.
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  alignContent: "flex-end",
                  cursor: "pointer",
                }}
              >
                <img
                  style={{ width: "55px", height: "45px" }}
                  src={dateCounter != 0 ? dateLeftBlack : dateLeft}
                  onClick={() =>
                    setState({
                      ...state,
                      dateCounter: dateCounter == 0 ? 0 : dateCounter - 1,
                    })
                  }
                ></img>
              </div>
              <div
                style={{
                  flex: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="danes"
                    style={{
                      fontFamily: "ProximaNova !important",
                    }}
                  >
                    Danes:
                  </div>
                  <div
                    style={{
                      fontFamily: "ProximaNova !important",
                      fontWeight: "700",
                      textTransform: "capitalize",
                    }}
                  >
                    {moment(
                      new Date(
                        new Date().setDate(new Date().getDate() + dateCounter)
                      ).toISOString()
                    )
                      .locale("sl")
                      .format("dddd L")}
                  </div>
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  alignContent: "flex-end",
                  cursor: "pointer",
                }}
              >
                <img
                  style={{ width: "55px", height: "45px" }}
                  src={dateRight}
                  onClick={() =>
                    setState({
                      ...state,
                      dateCounter: dateCounter == 2 ? 2 : dateCounter + 1,
                    })
                  }
                />
              </div>
            </div>
            <div className="timeSlots">{renderDateTimeSlots(dateCounter)}</div>
          </div>
        );
      default:
        return "Unknown step";
    }
  }

  const isStepOptional = (step) => {};

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <div className={classes.root} id="questionaryRoot">
      <div className="allFontsToBeBoston">
        <div className="questionaryView">
          {console.log(state)}

          {activeStep === steps.length ? (
            <div className="stepBoxFinal">
              <div className="davcnaFormCenter davcnaForm">
                <div className="paddedBox">
                  <h1>Uspešno ste rezervirali posvet</h1>
                  <p>
                    Potrdilo o uspešni rezervaciji termina in vabilo na posvet
                    boste prejeli na elektronski naslov in preko SMS sporočila.
                  </p>
                </div>
                <img src={registration_success}></img>
              </div>
            </div>
          ) : (
            <div className="stepBox">
              <Grid alignItems="center" container noWrap spacing={3}>
                <Grid item xs={12} xm={6} lg={6}>
                  <Typography className={classes.instructions}>
                    {getStepContent(activeStep, state)}
                  </Typography>
                </Grid>
                <Grid className="stepBoxImg" item xs={12} xm={6} lg={6}>
                  <div>
                    <img src={doctorIntro} />
                  </div>
                </Grid>
                <Grid item xs={12} xm={6} lg={6}>
                  <div className="stepBoxControll">
                    {activeStep === 0 ? (
                      <></>
                    ) : (
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                        style={{ background: "#E0E0E0" }}
                      >
                        Nazaj
                      </Button>
                    )}
                    {isStepOptional(activeStep) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSkip}
                        className={classes.button}
                      >
                        Skip
                      </Button>
                    )}

                    {stepValidator(activeStep)}
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
