import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import axios from "axios";
//images

import grozno_positive from "../../assets/rating/grozno.svg";
import slab_positive from "../../assets/rating/slab.svg";
import povprecno_positive from "../../assets/rating/povprecno.svg";
import dobro_positive from "../../assets/rating/dobro.svg";
import odlicno_positive from "../../assets/rating/odlicno.svg";
import grozno_negativ from "../../assets/grozno_negativ.svg";
import slab_negativ from "../../assets/slab_negativ.svg";
import povprecno_negativ from "../../assets/povprecno_negativ.svg";
import dobro_negativ from "../../assets/dobro_negativ.svg";
import odlicno_negativ from "../../assets/odlicno_negativ.svg";

import { FaFileSignature } from "react-icons/fa";

//Dinamic Images
const feedback_success =
  require(`../../assets/${process.env.REACT_APP_PROJECT}/feedback_success.png`).default;
const merkurLogo =
  require(`../../assets/${process.env.REACT_APP_PROJECT}/merkur.svg`).default;

const queryParams = new URLSearchParams(window.location.search);
const sessionId = queryParams.get("sessionId");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    padding: "1em 5em",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  padded: {
    padding: "3em 15em 2em 15em",
    background: "#A6D586",
  },
}));

export default function HorizontalLinearStepper({ ...props }) {
  import("./App.css");

  const history = useHistory();
  const handleClick = () => history.push("/");

  console.log(props);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();
  // The first commit of Material-UI

  const [state, setState] = React.useState({
    grozno: true,
    slab: true,
    povprecno: true,
    dobro: true,
    odlicno: true,
    vsebina: false,
    prijaznost: false,
    odnos: false,
    strokovnost: false,
    enostavnost: false,
    hitrost: false,
    drugo: "",
    placevanje: false,
    fiveEuros: false,
    tenEuros: false,
    twentyEuros: false,
    opinnionForPacient: "",
    oppinionForInsurance: "",
    forSpecialist: "",
    prikazi: true,
  });

  function getSteps() {
    return ["Prijava", "Izbira termina", "Zaključek"];
  }

  const {
    vsebina,
    prijaznost,
    odnos,
    strokovnost,
    enostavnost,
    hitrost,
    drugo,
    placevanje,
    fiveEuros,
    tenEuros,
    twentyEuros,
    willingToPay,
    ratingOptions,
    rating,
    opinnionForPacient,
    forSpecialist,
    oppinionForInsurance,
    prikazi,
  } = state;

  const handleSelection = (event) => {
    console.log(event);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <div className="canceledDiv">
            {state.prikazi ? (
              <>
                <h2>Ali ste prepričani da želite preklicati video posvet</h2>

                <div className="buttons">
                  {/* sendSurvey(state,props) *

           setState({ ...state, prikazi: false })
           */}
                  <Button onClick={handleClick}> Ne</Button>
                  <Button
                    className="canceledButton"
                    onClick={() => sendSurvey(state, props)}
                  >
                    {" "}
                    Da
                  </Button>
                </div>
              </>
            ) : (
              <>
                {" "}
                <h2> Uspešno ste preklicali video posvet </h2>{" "}
              </>
            )}
            {/* </div> */}
          </div>
        );

      default:
        return "Unknown step";
    }
  }

  const isStepOptional = (step) => {};

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Sends survey to database
  const sendSurvey = (state, props) => {
    console.log("Update survey with survey id", props);
    setState({ ...state, prikazi: false });
    axios
      .get(`${process.env.REACT_APP_API_URL}sessions/${sessionId}`,
      
      {
        headers: {
          'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
        }
      }

      )
      .then((response) => {
        console.log(response);
        axios.patch(`${process.env.REACT_APP_API_URL}sessions/${sessionId}`,
        {
          id: sessionId,
          callStatus: "Canceled",
          cancelationInitiator: "Pacient",
        },  {
          headers: {
            'x-api-key': `${process.env.REACT_APP_API_LOCAL_KEY}`
          }
        });
      });
  };

  return (
    <div className="canceledWiew">
      <div className={classes.root} id="feedbackRoot">
        <div id="logo" className="logo-relative-mobile">
          <img src={merkurLogo}></img>
        </div>
        {console.log("The end State", state, process.env.API_URL)}
        {/* <Stepper
        classes={{ root: classes.padded }}
        alternativeLabel
        activeStep={activeStep}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper> */}
        <div className="feedbackView">
          {activeStep === steps.length ? (
            <div className="feedbackFormCentered">
              <h1>Video posvet je končan</h1>
              <h2>Hvala za vaše mnenje</h2>
              <img
                className="imageResponsive"
                style={{ height: "55%", width: "auto" }}
                src={feedback_success}
              ></img>
            </div>
          ) : (
            <div className="stepBoxFeedback">
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
