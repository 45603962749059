import React, { Component } from "react";
import { createLocalVideoTrack, connect } from "twilio-video";
import 'zone.js/dist/zone';
import 'zone.js/dist/webapis-rtc-peer-connection'

import { Device } from 'twilio-client';
import axios from 'axios'
import('../App.css');
import('./site.css');

const device = new Device();
const queryParams = new URLSearchParams(window.location.search);

let phone = queryParams.get("phone");
const merkurLogo  = require(`../assets/${process.env.REACT_APP_PROJECT}/merkur.svg`).default;

phone = "+" + phone;
class Voice extends React.Component {

  


   componentDidMount() {

    console.log("Poslano od Videa", this.props.location)

    var speakerDevices = document.getElementById('speaker-devices');
    var ringtoneDevices = document.getElementById('ringtone-devices');
    var outputVolumeBar = document.getElementById('output-volume');
    var inputVolumeBar = document.getElementById('input-volume');
    var volumeIndicators = document.getElementById('volume-indicators');  

    log('Requesting Capability Token...');
   axios.get('https://fallow-mallard-7019.twil.io/capability-token')
  //Paste URL HERE
    .then((response) => {
      var data = response.data;
      console.log(data.token)
              
      device.setup(data.token);

      console.log(device)

      // Setup device
      device.setup(data.token);

      device.ready(function (device) {
        log('device Ready!');
        document.getElementById('call-controls').style.display = 'block';
      });

      device.error(function (error) {
        log('device Error: ' + error.message);
      });

      device.connect(function (conn) {
        log('Successfully established call!');
        document.getElementById('button-call').style.display = 'none';
        document.getElementById('button-hangup').style.display = 'inline';
        volumeIndicators.style.display = 'flex';
        bindVolumeIndicators(conn);
      });

      device.disconnect(function (conn) {
        log('Call ended.');
        document.getElementById('button-call').style.display = 'inline';
        document.getElementById('button-hangup').style.display = 'none';
        volumeIndicators.style.display = 'none';
      });

      device.incoming(function (conn) {
        log('Incoming connection from ' + conn.parameters.From);
        var archEnemyPhoneNumber = '+12099517118';

        if (conn.parameters.From === archEnemyPhoneNumber) {
          conn.reject();
          log('It\'s your nemesis. Rejected call.');
        } else {
          // accept the incoming connection and start two-way audio
          conn.accept();
        }
      });

      setClientNameUI(data.identity);

      device.audio.on('deviceChange', updateAllDevices);

      // Show audio selection UI if it is supported by the browser.
      if (device.audio.isSelectionSupported) {
        document.getElementById('output-selection').style.display = 'block';
      }
      console.log(data)
      log('Got a token.');
      console.log('Token: ' + data.token);
   
    })

  // Bind button to make call
  document.getElementById('button-call').onclick = function () {
    // get the phone number to connect the call to
    var params = {
      To: document.getElementById('phone-number').value
    };

    console.log('Calling ' + params.To + '...');
    device.connect(params);
  };

  // Bind button to hangup call
  document.getElementById('button-hangup').onclick = function () {
    log('Hanging up...');
    device.disconnectAll();
  };

  document.getElementById('get-devices').onclick = function() {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(updateAllDevices);
  };

  speakerDevices.addEventListener('change', function() {
    var selectedDevices = [].slice.call(speakerDevices.children)
      .filter(function(node) { return node.selected; })
      .map(function(node) { return node.getAttribute('data-id'); });
    
    device.audio.speakerDevices.set(selectedDevices);
  });

  ringtoneDevices.addEventListener('change', function() {
    var selectedDevices = [].slice.call(ringtoneDevices.children)
      .filter(function(node) { return node.selected; })
      .map(function(node) { return node.getAttribute('data-id'); });
    
    device.audio.ringtoneDevices.set(selectedDevices);
  });

  function bindVolumeIndicators(connection) {
    connection.volume(function(inputVolume, outputVolume) {
      var inputColor = 'red';
      if (inputVolume < .50) {
        inputColor = '#5CC8BD';
      } else if (inputVolume < .75) {
        inputColor = 'yellow';
      }

      inputVolumeBar.style.width = Math.floor(inputVolume * 300) + 'px';
      inputVolumeBar.style.background = inputColor;

      var outputColor = 'red';
      if (outputVolume < .50) {
        outputColor = '#5CC8BD';
      } else if (outputVolume < .75) {
        outputColor = 'yellow';
      }

      outputVolumeBar.style.width = Math.floor(outputVolume * 300) + 'px';
      outputVolumeBar.style.background = outputColor;
    });
  }

  function updateAllDevices() {
    updateDevices(speakerDevices, device.audio.speakerDevices.get());
    updateDevices(ringtoneDevices, device.audio.ringtoneDevices.get());
  }

  // Update the available ringtone and speaker devices
function updateDevices(selectEl, selectedDevices) {
  selectEl.innerHTML = '';
  device.audio.availableOutputDevices.forEach(function(device, id) {
    var isActive = (selectedDevices.size === 0 && id === 'default');
    selectedDevices.forEach(function(device) {
      if (device.deviceId === id) { isActive = true; }
    });

    var option = document.createElement('option');
    option.label = device.label;
    option.setAttribute('data-id', id);
    if (isActive) {
      option.setAttribute('selected', 'selected');
    }
    selectEl.appendChild(option);
  });
}

// Activity log
function log(message) {
  var logDiv = document.getElementById('log');
  logDiv.innerHTML += '<p>&gt;&nbsp;' + message + '</p>';
  logDiv.scrollTop = logDiv.scrollHeight;
}

// Set the client name in the UI
function setClientNameUI(clientName) {
  var div = document.getElementById('client-name');
  div.innerHTML = 'Your client name: <strong>' + clientName +
    '</strong>';
}

  }


  render() {
    console.log(this.state)
    return (
<div className="canceledWiew" >
    <div  id="feedbackRoot">    
              <div id="logo" className="logo-relative-mobile">
        <img src={merkurLogo}></img>
      </div>
 <div id="controls">
    <div id="info">
      <div id="output-selection">
        <label>Ringtone Devices</label>
        <select id="ringtone-devices" multiple></select>
        <label>Speaker Devices</label>
        <select id="speaker-devices" multiple></select><br/>
        <a id="get-devices">Seeing unknown devices?</a>
      </div>
    </div>
    <div id="call-controls">
      <p className="instructions-heading">Klic s pacientom</p>
      <p className="instructions-subheading">Pozovite vašega pacienta</p>
      <input id="phone-number" type="text" placeholder="Enter a phone # or client name" value={(this.props.location.state  == undefined) ? phone.slice(0,4) + phone.slice(5, phone.length) : this.props.location.state.pacientPhone.slice(0, 3) + this.props.location.state.pacientPhone.slice(4, this.props.location.state.pacientPhone.length) 
        } disabled />
      <div id="volume-indicators">
        <label>Mic Volume</label>
        <div id="input-volume"></div><br/><br/>
        <label>Speaker Volume</label>
        <div id="output-volume"></div>
      </div>
      <button id="button-call">Pozovite</button>
      <button id="button-hangup">Prekličite</button>
    </div>
    <div id="log"></div>
  </div>
      </div>
      </div>
    );
  }
}


export default Voice;
