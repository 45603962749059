import {createContext, useReducer} from 'react';

const initialState = {
    data: {
        isAuthenticated: false,
        user: [],
    },
};


export const store = createContext(initialState);

const { Provider } = store;

export const StateProvider = ({children}) => {
    const [state, dispatch] = useReducer((state, action) => {
        const currentState = { ...state };
        switch(action.type){
            case "SET_DATA": 
            currentState.data = action.payload;
            return currentState;
            case "LOGOUT": 
            currentState.data = null;
            return currentState;
            default:
                throw new Error();
        }
    }, initialState);

    return <Provider value={{state, dispatch}}>{children}</Provider>;
}

